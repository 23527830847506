import React, { Fragment, Suspense } from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { LinearProgress } from "@material-ui/core";
import { Redirect } from "react-router-dom";

import { Topbar } from "./components";

const useStyles = makeStyles(theme => ({
  content: {
    height: "100%",

    [theme.breakpoints.up("sm")]: {}
  }
}));

const Auth = props => {
  const { route } = props;

  const classes = useStyles();
  const findBallotId = path => {};

  return (
    <Fragment>
      {/* <Topbar /> */}

      {/* {process.env.REACT_APP_PROD === "YES" && (
        <Redirect to="/voter/index/5df09a43fe655922c325baca" />
      )} */}

      <main className={classes.content}>
        <Suspense fallback={<LinearProgress />}>
          {renderRoutes(route.routes)}
        </Suspense>
      </main>
    </Fragment>
  );
};

Auth.propTypes = {
  route: PropTypes.object
};

export default Auth;
