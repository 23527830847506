import React, { Component, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import useRouter from "utils/useRouter";
//import keen from 'utils/keen';
import KeenTracking from "keen-tracking";
import axios from "utils/axios";
import getBallotId from "utils/getBallotId";
import getSeoUnFriendlyWord from "utils/getSeoUnFriendlyWord";
import getSeoFriendlyWord from "utils/getSeoFriendlyWord";
import getCategorySubCategoryId from "utils/getCategorySubCategoryId";
//import KeenTracking from 'keen-tracking';
/* HOC for tracking page views with React Router */
const withKeenTrack = (WrappedComponent: React.ComponentType, options = {}) => {
  const trackPage = (page, ballotId) => {
    const client = new KeenTracking({
      projectId: process.env.REACT_APP_KEEN_PROJECT_ID,
      writeKey: process.env.REACT_APP_KEEN_WRITE_KEY,
    });

    const axios_url =
      process.env.REACT_APP_BACK_END_SERVER_URL +
      "/server/v1/ballots/" +
      ballotId;
    ballotId &&
      axios.get(axios_url).then((response) => {
        if (response.status === 200) {
          let catSubCatDetails = getCategorySubCategoryId(
            page,
            response.data,
            ballotId
          );
          const keenDetailsObj = catSubCatDetails;
          client.extendEvent("pageviews", {
            pathdetails: keenDetailsObj,
          });
          client.initAutoTracking({ recordPageViews: true });
        }
      });
  };
  const HOC = class extends Component {
    constructor(props) {
      super(props);
      this.state = { ballotId: undefined };
    }
    componentDidMount() {
      const page = this.props.location.pathname;

      trackPage(page, this.props.ballotId);
    }
    render() {
      return (
        <WrappedComponent ballotId={this.props.ballotId} {...this.props} />
      );
    }
  };
  return HOC;
};
export default withKeenTrack;
