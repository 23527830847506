import React, { Fragment, useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Avatar,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Typography,
  Fab,
  Badge
} from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/PeopleOutline";

import axios from "utils/axios";
import { StatusBullet } from "components";

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 280
  },
  root: {
    backgroundColor: theme.palette.white
  },
  list: {
    padding: theme.spacing(1, 3)
  },
  listItemText: {
    marginRight: theme.spacing(1)
  },
  lastActivity: {
    whiteSpace: "nowrap"
  },
  fab: {
    position: "fixed",
    bottom: 32,
    right: 32,
    zIndex: theme.zIndex.drawer - 100
  }
}));

const ChatBar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    let mounted = true;

    const fetchData = () => {
      // axios.get("/api/chat/activity").then(response => {
      //   if (mounted) {
      //     setData(response.data);
      //   }
      // });
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!data) {
    return null;
  }

  return (
    <Fragment>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawer }}
        elevation={1}
        onClose={handleClose}
        open={open}
        variant="temporary"
      ></Drawer>
      <span
        className={clsx(classes.fab, {
          [classes.shiftFab]: open
        })}
      >
        <Badge color="error">
          <Fab color="primary" onClick={handleOpen}>
            <PeopleIcon />
          </Fab>
        </Badge>
      </span>
    </Fragment>
  );
};

ChatBar.propTypes = {
  className: PropTypes.string
};

export default ChatBar;
