import axios from "axios";
// const jwt = require("jsonwebtoken");

// const payload = { name: "bestof" };

// const token = jwt.sign(payload, "bestof87989njyu67bv", {
//   expiresIn: "3650d",
// });
// console.log("jwt api token");
// console.log(token);
const instance = axios.create();
//console.log("creating header");

// instance.interceptors.request.use(function(config) {
//   config.headers.Authorization = token;
//   return config;
// });

export default instance;
