import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import Cookies from "js-cookie";
import { makeStyles } from "@material-ui/styles";
import useRouter from "utils/useRouter";
//connected_to_stripe

const useStyles = makeStyles((theme) => ({
  stripeLink: {
    cursor: "pointer",
  },
}));

const PaymentsAlert = (props) => {
  const classes = useStyles();
  const router = useRouter();
  const routeToStripeConnectLink = () => {
    let routePath =
      "/organization-settings/" + Cookies.get("customer_id") + "/basic";
    router.history.push(routePath);
  };
  return (
    Cookies.get("connected_to_stripe") != "true" && (
      <Alert severity="warning">
        <AlertTitle>
          Stripe Connect must be setup before payments can be accepted.
        </AlertTitle>
        <strong className={classes.stripeLink}>
          <a onClick={routeToStripeConnectLink}>Set Stripe up here</a>
        </strong>
      </Alert>
    )
  );
};

export default PaymentsAlert;
