import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none"
  },
  mainIframe: {
    zIndex: 100
  }
}));

const Topbar = props => {
  const { className, ...rest } = props;

  const getGeneratedPageURL = ({ html, css, js }) => {
    const getBlobURL = (code, type) => {
      const blob = new Blob([code], { type });
      return URL.createObjectURL(blob);
    };

    const cssURL = getBlobURL(css, "text/css");
    const jsURL = getBlobURL(js, "text/javascript");

    const source = `
      <html>
        <head>
          ${css && `<link rel="stylesheet" type="text/css" href="${cssURL}" />`}
          ${js && `<script src="${jsURL}"></script>`}
        </head>
        <body>
          ${html || ""}
        </body>
      </html>
    `;

    return getBlobURL(source, "text/html");
  };

  const html = `
  
  <!-- Styles -->

  <style>
      .remove-padding {
        padding: 0;
      }

      .sctk-logo-container {
        background-color: white;
        /*padding: 20px 0;*/
        font-family: georgia, times, serif;
      }

      .sctk-logo-container img {
        max-width: 100%;
      }

      .navbar-sctk {
        background: #2a2a2a;
        border: none;
        border-radius: 0;
        margin-bottom: 0;
      }

      .navbar-sctk li {
        padding: 0 5px;
      }

      .navbar-sctk li a {
        text-transform: lowercase;
        font-size: 14px;
        font-weight: 700;
        color: #008888;
      }

      .navbar-sctk li:hover {
        background: #ef8707;
      }

      .secondary-navbar-sctk {
        margin-top: 10px;
        /*background-color: #ddd;*/
      }

     .secondary-navbar-sctk .list-inline {
      margin: 0;
      padding: 0 15px;
      display: inline-block;
     } 

     .secondary-navbar-sctk li {
        margin: 2px 15px 0px 1px;
        font-family: georgia, times, serif;
      }

      .secondary-navbar-sctk li a {
        color: #008888;
        text-transform: lowercase;
        font-size: 14px;
      }

      .secondary-navbar-sctk li a:hover {
        text-decoration: none;
      }

      #socialbuttonsgroup {
        display: inline-block;
        float: right;
      }

      #socialbuttonsgroup li {
        display: inline-block;
        margin: 3px 2px 0px 2px;
        padding: 0;
      }

      .ccp-logo {
        width: 240px;
        height: 85px;
        border-right: solid 1px #7d7d7d;
        padding: 2px 0px 0px 15px;
      }


    </style>

    <style>
      div.searchbuttonswrapper {
       width: 300px;
       height: 85px;
       border-left: solid 1px #7d7d7d;
       padding: 5px 0 5px 5px;
       float: right;
       background-color: #f0bf3e;
      }
      div#searchbuttons {
       width: 100%;
       height: 100%;
       padding: 0px;
       margin: 0px;
       float: left;
      }
      div#searchbuttons a {
       display: block;
       float: left;
       background-color: #f0bf3e;
      }
      a.tinyrightmargin {
       padding-right: 7px
      }
      div.searchbuttonswrapper div.lowerbuttons a {
       margin: 0px 0px 0px 0px
      }
      div.searchbuttonswrapper a#SearchButtonsToday,
      div.searchbuttonswrapper a#SearchButtonsThisweek,
      div.searchbuttonswrapper a#SearchButtonsStaffPicks,
      div.searchbuttonswrapper a#SearchButtonsEventCalendar,
      div.searchbuttonswrapper a#SearchButtonsFindLiveMusic,
      div.searchbuttonswrapper a#SearchButtonsFindRestaurants {
       display: block;
       overflow: hidden;
       padding: 0px;
       margin: 0px;
       border: solid 1px #fff;
       text-indent: -9999px;
       -moz-border-radius: 5px; /* Firefox */
       -webkit-border-radius: 5px; /* Safari, Chrome */
       border-radius: 5px; /* CSS3 */
      }
      div#searchbuttons a:hover {
       border: solid 1px #ffffff
      }
      div.searchbuttonswrapper a#SearchButtonsToday {
       width: 82px;
       height: 11px;
       background: #f0bf3e url('https://www.charlestoncitypaper.com/images/buttons/searchbuttons.png') no-repeat -216px -6px;
       border: 0px;
       -moz-border-radius: 0px; /* Firefox */
       -webkit-border-radius: 0px; /* Safari, Chrome */
       border-radius: 0px; /* CSS3 */
      }
      div.searchbuttonswrapper a#SearchButtonsToday:hover {
       width: 82px;
       height: 11px;
       background: #f0bf3e  url('https://www.charlestoncitypaper.com/images/buttons/searchbuttons.png') no-repeat -216px -81px;
      }
      div.searchbuttonswrapper a#SearchButtonsThisweek {
       width: 82px;
       height: 11px;
       background: #f0bf3e url('https://www.charlestoncitypaper.com/images/buttons/searchbuttons.png') no-repeat -216px -17px;
       border: 0px;
       -moz-border-radius: 0px; /* Firefox */
       -webkit-border-radius: 0px; /* Safari, Chrome */
       border-radius: 0px; /* CSS3 */
      }
      div.searchbuttonswrapper a#SearchButtonsThisweek:hover {
       background: #f0bf3e url('https://www.charlestoncitypaper.com/images/buttons/searchbuttons.png') no-repeat -216px -92px
      }
      div.searchbuttonswrapper a#SearchButtonsStaffPicks {
       width: 82px;
       height: 11px;
       background: #f0bf3e url('https://www.charlestoncitypaper.com/images/buttons/searchbuttons.png') no-repeat -216px -28px;
       border: 0px;
       -moz-border-radius: 0px; /* Firefox */
       -webkit-border-radius: 0px; /* Safari, Chrome */
       border-radius: 0px; /* CSS3 */
      }
      div.searchbuttonswrapper a#SearchButtonsStaffPicks:hover {
       background: #f0bf3e url('https://www.charlestoncitypaper.com/images/buttons/searchbuttons.png') no-repeat -216px -103px
      }
      div.searchbuttonswrapper a#SearchButtonsEventCalendar {
       width: 200px;
       height: 35px;
       background: #f0bf3e url('https://www.charlestoncitypaper.com/images/buttons/searchbuttons.png') no-repeat -5px -4px;
      }
      div.searchbuttonswrapper a#SearchButtonsEventCalendar:hover {
       background: #f0bf3e url('https://www.charlestoncitypaper.com/images/buttons/searchbuttons.png') no-repeat -5px -79px
      }
      div.searchbuttonswrapper a#SearchButtonsFindLiveMusic {
       width: 135px;
       height: 27px;
       background: #f0bf3e url('https://www.charlestoncitypaper.com/images/buttons/searchbuttons.png') no-repeat -4px -46px;
       margin-right: 4px;
       margin-top: 4px;
      }
      div.searchbuttonswrapper a#SearchButtonsFindLiveMusic:hover {
       background: #f0bf3e url('https://www.charlestoncitypaper.com/images/buttons/searchbuttons.png') no-repeat -4px -121px
      }
      div.searchbuttonswrapper a#SearchButtonsFindRestaurants {
       width: 151px;
       height: 27px;
       background: #f0bf3e url('https://www.charlestoncitypaper.com/images/buttons/searchbuttons.png') no-repeat -147px -46px;
       margin-top: 4px;
      }
      div.searchbuttonswrapper a#SearchButtonsFindRestaurants:hover {
       background: #f0bf3e url('https://www.charlestoncitypaper.com/images/buttons/searchbuttons.png') no-repeat -147px -121px
      }
      div.searchbuttonswrapper div.lowerbuttons {
       margin: 3px 0px 0px 0px
      }

      div#NavigationHorizontal {
       padding: 0px;
       margin: 0px;
       height: 44px;
       width: 980px;
       margin: 2px 0px 0px 0px;
      }









      /* Navigation */
      div#NavigationHorizontal {
       padding: 0px;
       margin: 0px;
       margin-bottom: 10px;
       height: 44px;
       width: 980px;
       margin: 2px 0px 0px 0px;
      }
      ul#mainNav {
       height: 44px;
       padding-left: 15px;
      }
      ul#mainNav li {
       display: inline
      }
      ul#mainNav li a {
       display: block;
       float: left;
       height: 44px;
       margin: 0px 0px 0px 20px;
       padding: 0;
       background-position: center 0;
       background-repeat: no-repeat;
       text-indent: -6000px;
      }
      ul#mainNav li a.hover {
       background-position: center -44px
      }
      ul#mainNav li.last a {
       border-right: 0
      }
      ul#mainNav li.navCurrent a {
       display: block;
       float: left;
       height: 44px;
       margin: 0px 0px 0px 20px;
       padding: 0;
       background-position: center -44px;
      }
      #NavigationHorizontal ul li ul {
       /*  display: none; _NO__DOTCOMMA__AFTER__*/
      }
      a#SNAVL_FoodDrink {
       width: 153px;
       background-image: url(https://www.charlestoncitypaper.com/images/home/nav/fooddrink.gif);
      }
      a#SNAVL_MusicClubs {
       width: 165px;
       background-image: url(https://www.charlestoncitypaper.com/images/home/nav/musicclubs.gif);
      }
      a#SNAVL_NewsOpinion {
       width: 176px;
       background-image: url(https://www.charlestoncitypaper.com/images/home/nav/newsopinion.gif);
      }
      a#SNAVL_ArtsMovies {
       width: 165px;
       background-image: url(https://www.charlestoncitypaper.com/images/home/nav/artsmovies.gif);
      }
      a#SNAVL_CalendarScene {
       width: 201px;
       background-image: url(https://www.charlestoncitypaper.com/images/home/nav/calendarscene.gif);
      }
      #NavigationHorizontal ul.subnav_content {
       position: absolute;
       width: 350px;
       min-height: 15px;
       background-color: #ffffff;
       border: 2px solid #8eb123;
       border-top: 0px;
       padding: 0;
       z-index: 9999;
       line-height: 1em;
       background: url('/images/home/greyback.gif');
       -moz-box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.3);
       box-shadow: 1px 3px 3px #666666;
       -webkit-box-shadow: 1px 3px 3px #666666;
       min-height:230px;
      }
      #NavigationHorizontal ul.subnav_content li {
       padding: 0px 0;
       margin: 0;
       position: relative;
       font-size: 12px;
       float: none;
      }
      #NavigationHorizontal ul.subnav_content li.subnav_left {

      }
      #NavigationHorizontal ul.subnav_content li.subnav_right {
       font-size: 15px;
       position: absolute;
       top: 0px;
       left: 200px;
       border-top: solid 1px #8eb123;
      }
      #NavigationHorizontal ul.subnav_content li.subnav_left ul.subnav_headlines {
       display: block;
       width: 200px;
       background-color: #ffffff;
      }
      span.newheadline a {
       font-size: 8px;
       font-family: verdana, arial, helvetica, sans-serif;
       color: #f90;
      }
      #NavigationHorizontal ul.subnav_content li.subnav_right ul.subnav_fixed {
       display: block;
       width: 150px;
       padding-top: 5px;
      }
      #NavigationHorizontal ul.subnav_content li a {
       display: block;
       padding: 5px;
       margin: 0;
       text-decoration: none;
       text-indent: 0;
       font-family: Georgia, Times, Times New Roman, serif;
       text-transform: none;
       color: #000;
       line-height: 1.2em;
       border-top: 0;
      }
      #NavigationHorizontal ul.subnav_content li.subnav_left ul.subnav_headlines li a {
       border-bottom: dotted 1px #464646;
       margin: 0px 5px;
      }
      #NavigationHorizontal ul.subnav_content li.subnav_left ul.subnav_headlines li.lastheadline a {
       border-bottom: 0
      }
      #NavigationHorizontal ul.subnav_content li.subnav_right ul.subnav_fixed li a {
       border-top: 0
      }
      #NavigationHorizontal ul.subnav_content li.subnav_right ul.subnav_fixed a {
       color: #8eb123;
       font-size: 15px;
      }
      #NavigationHorizontal ul.subnav_content li.subnav_right ul.subnav_fixed a:hover {
       color: #008888;
       font-size: 15px;
      }
      #NavigationHorizontal ul.subnav_content li a:hover {
       text-decoration: underline;
       color: #000;
       filter: alpha(opacity=100);
       opacity: 1;
      }
      #NavigationHorizontal li#navigationmorelink a {
       font-family: Georgia, Times, Times New Roman, serif;
       font-size: 14px;
       font-style: italic;
       font-weight: bold;
       color: #f0bf3e;
       border: 0px;
       padding: 10px;
      }
      #NavigationHorizontal li#navigationmorelink {
       text-align: center
       margin-bottom: 10px;
      }
      /*

            #NavigationHorizontal ul.subnav_content li.searchItem {
                  filter:alpha(opacity=100);
        opacity:1;
        }


            #NavigationHorizontal ul.subnav_content li.searchItem a {
              background:url(https://www.charlestoncitypaper.com/images/icons/navmag.gif) no-repeat 6px 3px;
              padding:3px 5px 3px 24px;
          color: #7c7c7c;font-weight:bold;background-color:#F0BF3E; font-size:12px;

            }
        #NavigationHorizontal ul.subnav_content li.searchItem a:hover {
              background:url(https://www.charlestoncitypaper.com/images/icons/navmag.gif) no-repeat 6px 3px;
              padding:3px 5px 3px 24px;
          background-color: #F0BF3E; color: #000;font-weight:bold;;font-size:12px;
            }

            */
      #NavigationHorizontal ul.subnav_content li.blogItem a {
       background: url(https://www.charlestoncitypaper.com/images/icons/blogicon.gif) no-repeat 6px 4px;
       padding: 2px 5px 3px 27px;
       font-size: 14px !important;
      }
      #NavigationHorizontal ul.subnav_content li.blogItem a:hover {
       background: url(https://www.charlestoncitypaper.com/images/icons/blogicon.gif) no-repeat 6px 4px;
       padding: 2px 5px 3px 27px;
      }

      .container {
        max-width: 1000px !important;
      }
    </style>

    <!-- Header Starts Here -->
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>

<!-- Latest compiled JavaScript -->
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>



    <!-- Mobile Header -->

    <style>
      .sctk-mobile-nav-inner {
        background-color: white;
        position: absolute;
        width: 100vw;
        height: 55px;
        border-color: #CDCDCD;
        border-style: solid;
        border-width: 0 0 2px;
      }

      .sctk-mobile-nav-inner .fa-bars {
        position: absolute;
        top: 15px;
        left: 20px;
        color: black;
        font-size: 26px;
      }

      .sctk-mobile-overlay .fa-times {
        position: absolute;
        top: 15px;
        right: 30px;
        color: white;
        font-size: 36px;
      }

      .sctk-mobile-nav-inner img {
        display: block;
        margin: 0px auto 0;
        max-height: 52px;
      }

      .sctk-mobile-overlay {
        position: fixed;
        padding-top: 50px;
        top: 0;
        left: 0;
        width: 285px;
        height: 100vh;
        display: none;
        background-color: white;
        box-shadow: 5px 0 5px rgba(0,0,0,.15);
      }

      .sctk-mobile-social {
        margin-bottom: 30px;
      }

      .sctk-mobile-social a {
        color: black;
        font-size: 15px;
        margin-right: 30px;
      }

      .sctk-mobile-pages{

      }

      .sctk-mobile-pages ul {
        margin: 0;
        padding: 0;
      } 

      .sctk-mobile-pages ul li {
        list-style: none;
      }

      .sctk-mobile-pages ul li a{
        font-family: 'Open Sans';
        color: #F0BF3E;
        margin-left: 0;
        font-weight: bold;
        display: block;
        text-transform: uppercase;
      }

      .sctk-utility-show {
        display: block;
      }


      .item  {
        border-width: 1px 0 0;
        border-color: #CDCDCD;
        border-style: solid;
        padding-left: 25px;
      }

      .item a {
        font-size: 16px;
        padding: 5px 30px 5px 0;
      }

      .large .item a{
        font-family: 'Open Sans';
        line-height: 21px;
        font-size: 21px;
        color: #F0BF3E;
        margin-left: 0;
        padding: 10px 30px 10px 0;
        font-weight: bold;
        display: block;
        text-transform: uppercase;
      }

      .large {
        margin-bottom: 25px !important;
        border-width: 0 0 1px;
        border-color: #CDCDCD;
        border-style: solid;
      }
.sctk-mobile-nav {
  display: none;
}

    </style>
    <style>
      #calendar-header {
margin-bottom: 10px;
}

.navbar-default .navbar-nav>li>a.header-add-event-btn, .custom--primary--background {
background-color: #f0bf3e
}



@media only screen and (max-width:991px) { 
#events {padding: 55px 0;}
}
    </style>



    <script src="https://use.fontawesome.com/030bf028ac.js"></script>

    
    
    <div class="sctk-mobile-nav visible-sm-block visible-xs-block">
      <div class="sctk-mobile-nav-inner">
        <i class="fa fa-bars sctk-mobile-nav-toggle"
        onclick="toggle_visibility('toggle-mobile-cp');"  
         > </i>

        <img src="https://m.charlestoncitypaper.com/images/mobile/header.png">
      </div>

      <div class="sctk-mobile-overlay" id="toggle-mobile-cp" style="display: none, z-index: 1" >
        <i class="fa fa-times sctk-mobile-nav-toggle" onclick="toggle_visibility('toggle-mobile-cp');" > </i>

        <div class="sctk-mobile-pages">


<div class="inner"><ul class="ui list grouped large">
  

  

  

  <li class="item">
    <a class="icon-right carat-r" href="https://m.charlestoncitypaper.com/charleston/Home" target="_self">
      Home
    </a>
  </li>

  



  

  

  <li class="item">
    <a class="icon-right carat-r" href="https://m.charlestoncitypaper.com/charleston/cuisine/Section?oid=1072085" target="_self">
      Food+Drink
    </a>
  </li>

  



  

  

  <li class="item">
    <a class="icon-right carat-r" href="https://m.charlestoncitypaper.com/charleston/music/Section?oid=1072086" target="_self">
      Music+Clubs
    </a>
  </li>

  



  

  

  <li class="item">
    <a class="icon-right carat-r" href="https://m.charlestoncitypaper.com/charleston/news/Section?oid=1072081" target="_self">
      News+Opinion
    </a>
  </li>

  



  

  

  <li class="item">
    <a class="icon-right carat-r" href="https://m.charlestoncitypaper.com/charleston/arts/Section?oid=1072083" target="_self">
      Arts+Movies
    </a>
  </li>

  



  

  

  <li class="item">
    <a class="icon-right carat-r" href="https://m.charlestoncitypaper.com/charleston/calendar/Section?oid=1072082" target="_self">
      Calendar+Scene
    </a>
  </li>

  



  

  

  <li class="item">
    <a class="icon-right carat-r" href="https://m.charlestoncitypaper.com/charleston/BestOf" target="_self">
      Best Of
    </a>
  </li>

  


    </ul><ul class="ui list grouped">
  

  

  <li class="item">
    <a class="icon-right carat-r" href="http://exchange.charlestoncitypaper.com" target="_self">
      Classifieds
    </a>
  </li>

  



  

  <li class="item">
    <a class="icon-right carat-r" href="https://m.charlestoncitypaper.com/charleston/EventSearch" target="_self">
      Events
    </a>
  </li>

  



  

  <li class="item">
    <a class="icon-right carat-r" href="https://m.charlestoncitypaper.com/charleston/MovieTimes?show=times&amp;narrowByDate" target="_self">
      Movie Times
    </a>
  </li>

  



  

  <li class="item">
    <a class="icon-right carat-r" href="https://m.charlestoncitypaper.com/charleston/LocationSearch" target="_self">
      Find Locations
    </a>
  </li>

  



  

  <li class="item">
    <a class="icon-right carat-r" href="https://m.charlestoncitypaper.com/charleston/IssueArchives" target="_self">
      Issue Archives
    </a>
  </li>

  


    </ul><ul data-view="loggedin" style="display:none" class="ui list grouped">
  <li class="item">
    <a class="icon-left user disabled">
      <strong data-label="username">Username</strong>
    </a>
    </li>
  
  
  <li class="item"><a data-action="logout" href="https://m.charlestoncitypaper.com/charleston/Logout">Log Out</a></li>
</ul><ul data-view="loggedout" class="ui list grouped">
  <li class="item "><a data-action="login" href="#LogIn">Sign in</a></li>
  <li class="item "><a data-action="createAccount" href="#CreateAccount">Create Account</a></li>
</ul></div>


        </div>

      </div>
    </div>
    

    <script type="text/javascript">
    
        function toggle_visibility(id) {
           var e = document.getElementById(id);
           if(e.style.display == 'block')
              e.style.display = 'none';
           else
              e.style.display = 'block';
        }
    
    </script>
  


    <!-- End Mobile Header -->

    <div style="max-width: 1000px; margin: 0 auto;">
      <!-- Secondary Nav Starts -->
      <div class="container-fluid secondary-navbar-sctk hidden-xs hidden-sm remove-padding hidden-sm hidden-xs">
        <div class="row">
          <div class="container-fluid" style="padding-left: 0;">
            <ul class="list-inline"> 
              <li><a href="http://exchange.charlestoncitypaper.com/sc/jobs/search">Jobs</a></li>
              <li><a href="http://exchange.charlestoncitypaper.com/sc/auto/search">Cars</a></li>
              <li><a href="http://exchange.charlestoncitypaper.com/sc/real-estate/search">Homes</a></li>
              <li><a href="http://exchange.charlestoncitypaper.com/sc/rentals/search">Rentals</a></li>
              <li><a href="http://exchange.charlestoncitypaper.com/sc/pets/search">Pets</a></li>
              <li><a href="http://exchange.charlestoncitypaper.com/sc/services/search">Services</a></li>
              <li><a href="http://citypapertickets.com/">Tickets</a></li>
            </ul>

            <ul id="socialbuttonsgroup" class="socialbuttons">

              <li><a href="https://www.charlestoncitypaper.com/charleston/twitter/page"><img src="https://www.charlestoncitypaper.com/images/home/twitter.gif" alt="Twitter" title="Twitter" border="0" width="16" height="16"></a></li>
              <li><a href="https://www.facebook.com/charlestoncitypaper?filter=1" target="_blank"><img src="https://www.charlestoncitypaper.com/images/home/facebook.gif" alt="Facebook" title="Facebook" border="0" width="16" height="16"></a></li>
              <li><a href="https://www.instagram.com/chascitypaper/" target="_blank"><img src="https://www.charlestoncitypaper.com/images/home/ig.gif" alt="Instagram" title="Instagram" border="0" width="16" height="16"></a></li>
              <li><a href="https://pinterest.com/chascitypaper" target="_blank"><img src="https://www.charlestoncitypaper.com/images/home/pinterest.gif" alt="Pinterest" title="Pinterest" border="0" width="16" height="16"></a></li>
              <li><a href="https://www.youtube.com/user/charlestoncitypaper/videos" target="_blank"><img src="https://www.charlestoncitypaper.com/images/home/youtube.gif" alt="YouTube" title="YouTube" border="0" width="16" height="16"></a></li>    
              <li><a href="http://charlestoncitypaper.tumblr.com/" target="_blank"><img src="https://www.charlestoncitypaper.com/images/home/tumblr.gif" alt="Tumblr" title="Tumblr" border="0" target="_blank" width="16" height="16"></a></li>
            
            </ul>
          </div>
        </div>
      </div>
      <!-- Secondary Nav Ends -->


      <!-- Logo -->
      <div class="container-fluid sctk-logo-container hidden-sm hidden-xs">
        <div class="row">
          <div class="container-fluid remove-padding" style="border: solid 1px #7d7d7d;">
            <div class="col-sm-12 col-md-6 remove-padding">
              <div class="ccp-logo">
                <a href="#"><img src="https://www.charlestoncitypaper.com/images/home/logo.gif"></a>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 remove-padding">
              <div class="searchbuttonswrapper">
            <div id="searchbuttons">
              <div class="upperbuttons">
                <div class="searchcriteria">
                  <a id="SearchButtonsToday" href="https://www.charlestoncitypaper.com/charleston/EventSearch?narrowByDate=Today" title="What's happening today">What's happening today</a><a id="SearchButtonsThisweek" href="https://www.charlestoncitypaper.com/charleston/thisweek/page" title="What's happening this week">What's happening this week</a><a id="SearchButtonsStaffPicks" href="https://www.charlestoncitypaper.com/charleston/EventSearch?feature=Staff Pick" title="Staff Picks">Staff Picks</a>
                </div><a id="SearchButtonsEventCalendar" href="https://www.charlestoncitypaper.com/charleston/EventSearch" title="Complete calendar listings">Calendar Listings</a>
              </div>
              <div class="lowerbuttons">
                <a id="SearchButtonsFindLiveMusic" href="https://www.charlestoncitypaper.com/gyrobase/EventSearch?eventSection=1067887&amp;eventCategory=1069927" title="Find live music">Find Live Music</a> <a id="SearchButtonsFindRestaurants" href="https://www.charlestoncitypaper.com/charleston/LocationSearch?locationSection=1063747" title="Find restaurants">Find Restaurants</a>
              </div>
            </div>
          </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row hidden-sm hidden-xs">
        <div class="container" style="text-align: center;">
          <div id="NavigationHorizontal" class="GlobalHeader Home ">

    



    

    
    
          <ul id="mainNav">
        
        

        

        
        
        

        <li class="first" id="NavItem_FoodDrink">
          <a href="https://www.charlestoncitypaper.com/charleston/cuisine/Section?oid=1072085" class="subnav_link" id="SNAVL_FoodDrink" target="_self">Food+Drink</a>
        </li>
        

      
        

        

        
        
        

        <li class="" id="NavItem_MusicClubs">
          <a href="https://www.charlestoncitypaper.com/charleston/music/Section?oid=1072086" class="subnav_link" id="SNAVL_MusicClubs" target="_self">Music+Clubs</a>
        </li>
        

      
        

        

        
        
        

        <li class="" id="NavItem_NewsOpinion">
          <a href="https://www.charlestoncitypaper.com/charleston/news/Section?oid=1072081" class="subnav_link" id="SNAVL_NewsOpinion" target="_self">News+Opinion</a>
        </li>
        

      
        

        

        
        
        

        <li class="" id="NavItem_ArtsMovies">
          <a href="https://www.charlestoncitypaper.com/charleston/arts/Section?oid=1072083" class="subnav_link" id="SNAVL_ArtsMovies" target="_self">Arts+Movies</a>
        </li>
        

      
        

        

        
        
        

        <li class="last" id="NavItem_CalendarScene">
          <a href="https://www.charlestoncitypaper.com/charleston/calendar/Section?oid=1072082" class="subnav_link" id="SNAVL_CalendarScene" target="_self">Calendar+Scene</a>
        </li>
        

      
          </ul>
        


      
      


      
      
         
          <h3 class="hidden">Browse News+Opinion</h3>
          <ul class="subnav_content" id="SNAV_NewsOpinion" style="display:none;">
           <li class="subnav_left">

          
          
               <ul id="subnav_headlines-trackme" class="subnav_headlines">






              

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/TheBattery/archives/2019/12/09/cunningham-signs-on-to-bill-to-advance-tax-credits-for-new-parents">Cunningham signs on to bill to advance tax credits for new parents<img src="https://www.charlestoncitypaper.com/images/home/new.gif"></a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/TheBattery/archives/2019/12/09/be-sure-to-use-your-holiday-magic-parking-voucher-around-town-this-season">Be sure to use your holiday magic parking voucher around town this season<img src="https://www.charlestoncitypaper.com/images/home/new.gif"></a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/TheBattery/archives/2019/12/04/elizabeth-warren-appearing-with-marlon-kimpson-at-cofc-bully-pulpit-forum-this-sunday">Elizabeth Warren appearing with Marlon Kimpson at CofC Bully Pulpit forum this Sunday</a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/charleston/transgender-sc-children-could-be-harmed-with-bill-advocates-say/Content?oid=30020883">Transgender S.C. children could be harmed with bill, advocates say</a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/charleston/stegelin-if-no-means-no-yes-means/Content?oid=30021363">Stegelin: If no means no, yes means...</a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/charleston/free-press-needed-now-more-than-ever/Content?oid=30020896">Free press needed now more than ever</a>
             </li>

            
          
               <li id="navigationmorelink">
               <a href="https://www.charlestoncitypaper.com/gyrobase/ArticleArchives?section=oid:1072081">{ more }</a>
               </li>
            </ul>
            

          </li>
          <li class="subnav_right">
          <ul id="subnav_fixed-trackme" class="subnav_fixed">

        
        
        
        

        <li class="blogItem">
          <a href="https://www.charlestoncitypaper.com/blogs/TheBattery/" target="_self">The Battery</a>
        </li>

        


      
         
        
        
        

        <li class="">
          <a href="https://www.charlestoncitypaper.com/charleston/commentarchives" target="_self">Recent Comments</a>
        </li>

        


      
         
        
        
        

        <li class="">
          <a href="https://www.charlestoncitypaper.com/charleston/blotter/Content?category=1072145" target="_self">Blotter</a>
        </li>

        


      
         
        
        
        

        <li class="">
          <a href="https://www.charlestoncitypaper.com/charleston/CityPaperPodcasts/Page" target="_self">CP Podcasts</a>
        </li>

        


      
         
        
        
        

        <li class="">
          <a href="https://www.charlestoncitypaper.com/charleston/SubmitALetter/Page" target="_self">Submit a letter to the editor</a>
        </li>

        


      
         
        
        
        

        <li class="">
          <a href="https://www.charlestoncitypaper.com/charleston/NewsTips/Page" target="_self">Submit a news tip</a>
        </li>

        
          </ul> </li></ul> <!-- check 6 - Submit a news tip - 98 -->
        


      
         
          <h3 class="hidden">Browse Calendar+Scene</h3>
          <ul class="subnav_content" id="SNAV_CalendarScene" style="display:none;">
           <li class="subnav_left">

          
          
               <ul id="subnav_headlines-trackme" class="subnav_headlines">






              

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/charleston/joe-riley-park-will-host-rebeccafest-a-batten-disease-research-benefit-in-honor-of-riverdogs-owner-mike-veecks-daughter/Content?oid=29953730">Joe Riley Park will host RebeccaFest, a Batten Disease research benefit in honor of RiverDogs owner Mike Veeck's daughter</a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/charleston/charlestons-historic-homes-offer-holiday-centric-tours/Content?oid=30021382">Charleston's historic homes offer holiday-centric tours</a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/charleston/2019-gift-guide-gifts-for-him/Content?oid=30010954">2019 Gift Guide: Gifts for Him</a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/charleston/check-out-the-gibbes-annual-king-street-antique-stroll-this-wed-dec-4/Content?oid=29962442">Check out the Gibbes' annual King Street Antique Stroll this Wed. Dec. 4</a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/charleston/holiday-happenings-15-parades-and-tree-lightings-in-charleston-this-december/Content?oid=29865800">Holiday happenings: 15+ parades and tree lightings in Charleston this December</a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/charleston/weekend-roundup-20-fun-ways-to-spend-the-thanksgiving-weekend/Content?oid=29959728">Weekend Roundup: 20+ fun ways to spend the Thanksgiving weekend</a>
             </li>

            
          
               <li id="navigationmorelink">
               <a href="https://www.charlestoncitypaper.com/gyrobase/ArticleArchives?section=oid:1072082">{ more }</a>
               </li>
            </ul>
            

          </li>
          <li class="subnav_right">
          <ul id="subnav_fixed-trackme" class="subnav_fixed">

        
        
        
        

        <li class="blogItem">
          <a href="https://www.charlestoncitypaper.com/blogs/seersucker" target="_self">Seersucker &amp; Stilettos</a>
        </li>

        


      
         
        
        
        

        <li class="blogItem">
          <a href="https://www.charlestoncitypaper.com/blogs/chsdeals" target="_self">CP Perks</a>
        </li>

        


      
         
        
        
        

        <li class="">
          <a href="https://www.charlestoncitypaper.com/charleston/thisweek/page" target="_self">Coming up this week</a>
        </li>

        


      
         
        
        
        

        <li class="searchItem">
          <a href="https://www.charlestoncitypaper.com/charleston/EventSearch?narrowByDate=Today&amp;eventSection=1069890" target="_self">Search events</a>
        </li>

        


      
         
        
        
        

        <li class="">
          <a href="https://www.charlestoncitypaper.com/beachguide" target="_self">Beach Guide</a>
        </li>

        


      
         
        
        
        

        <li class="">
          <a href="http://citypapertickets.com" target="_blank">Buy Tickets</a>
        </li>

        


      
         
        
        
        

        <li class="">
          <a href="https://www.charlestoncitypaper.com/charleston/events/addevent" target="_self">Submit an event</a>
        </li>

        
          </ul> </li></ul> <!-- check 13 - Submit an event - 10 -->
        


      
         
          <h3 class="hidden">Browse Arts+Movies</h3>
          <ul class="subnav_content" id="SNAV_ArtsMovies" style="display:none;">
           <li class="subnav_left">

          
          
               <ul id="subnav_headlines-trackme" class="subnav_headlines">






              

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/charleston/review-charleston-stages-elf-plays-it-safe-with-holiday-tunes-and-the-films-familiar-storyline/Content?oid=30067353">Review: Charleston Stage's "Elf" plays it safe with holiday tunes and the film's familiar storyline</a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/CultureShock/archives/2019/12/07/call-for-submissions-artfields-jr-looking-for-work-from-students-grades-1-12">Call for submissions: ArtFields Jr. looking for work from students grades 1-12</a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/CultureShock/archives/2019/12/06/baby-shark-live-splashes-onto-the-npac-stage-next-may">Baby Shark Live! splashes onto the NPAC stage next May</a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/CultureShock/archives/2019/12/05/wild-common-hosts-holiday-artist-workshop-on-sat-dec-14">Wild Common hosts holiday artist workshop on Sat. Dec. 14</a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/CultureShock/archives/2019/12/05/dontre-majors-first-solo-exhibition-black-america-resilient-opens-at-redux-this-fri-dec-6">Dontre Major's first solo exhibition, "Black America: Resilient," opens at Redux this Fri. Dec. 6</a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/CultureShock/archives/2019/12/05/charleston-rhizome-collective-launches-mobile-app-this-sun-dec-8">Charleston Rhizome Collective launches mobile app this Sun. Dec. 8</a>
             </li>

            
          
               <li id="navigationmorelink">
               <a href="https://www.charlestoncitypaper.com/gyrobase/ArticleArchives?section=oid:1072083">{ more }</a>
               </li>
            </ul>
            

          </li>
          <li class="subnav_right">
          <ul id="subnav_fixed-trackme" class="subnav_fixed">

        
        
        
        

        <li class="blogItem">
          <a href="https://www.charlestoncitypaper.com/blogs/cultureshock" target="_self">Culture Shock</a>
        </li>

        


      
         
        
        
        

        <li class="blogItem">
          <a href="https://www.charlestoncitypaper.com/blogs/holycinema" target="_self">Holy Cinema</a>
        </li>

        


      
         
        
        
        

        <li class="blogItem">
          <a href="https://www.charlestoncitypaper.com/blogs/spoletobuzz" target="_self">Spoleto Buzz</a>
        </li>

        


      
         
        
        
        

        <li class="">
          <a href="https://www.charlestoncitypaper.com/fallarts" target="_self">Fall Arts Issue</a>
        </li>

        


      
         
        
        
        

        <li class="">
          <a href="https://www.charlestoncitypaper.com/charleston/ArticleArchives?category=1072155" target="_self">Film Reviews</a>
        </li>

        


      
         
        
        
        

        <li class="">
          <a href="https://www.charlestoncitypaper.com/charleston/MovieTimes?narrowByDate=Today" target="_self">Movie Times</a>
        </li>

        


      
         
        
        
        

        <li class="">
          <a href="https://www.charlestoncitypaper.com/spoleto" target="_self">Spoleto 2019</a>
        </li>

        
          </ul> </li></ul> <!-- check 20 - Spoleto 2019 - 99 -->
        


      
         
          <h3 class="hidden">Browse Food+Drink</h3>
          <ul class="subnav_content" id="SNAV_FoodDrink" style="left: 252px; top: 171px; display: none;">
           <li class="subnav_left">

          
          
               <ul id="subnav_headlines-trackme" class="subnav_headlines">






              

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/Eat/archives/2019/12/09/whats-poppin-35-ways-to-eat-and-drink-around-town-this-week">What's Poppin': 35+ ways to eat and drink around town this week<img src="https://www.charlestoncitypaper.com/images/home/new.gif"></a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/Eat/archives/2019/12/06/minero-announces-johns-island-location-coming-summer-2020">Minero bringing its tacos, burritos, and wings to Johns Island in summer 2020</a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/Eat/archives/2019/12/05/swank-desserts-hosts-grand-opening-of-summerville-storefront-this-sat-dec-7">Swank Desserts hosts grand opening of Summerville storefront this Sat. Dec. 7</a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/Eat/archives/2019/12/05/holy-city-brewing-hopes-to-open-spacious-new-park-circle-area-brewery-next-week">Holy City Brewing hopes to open spacious new Park Circle-area brewery next week</a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/Eat/archives/2019/12/05/thoroughbred-club-and-charleston-grill-launch-world-class-champagne-tastings">Thoroughbred Club and Charleston Grill launch "world-class" Champagne tastings</a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/charleston/handcrafts-miracle-bar-pop-up-is-over-the-top-nostalgia-inducing-fun/Content?oid=30010590">Handcraft’s Miracle Bar pop-up is over-the-top, nostalgia-inducing fun</a>
             </li>

            
          
               <li id="navigationmorelink">
               <a href="https://www.charlestoncitypaper.com/gyrobase/ArticleArchives?section=oid:1072085">{ more }</a>
               </li>
            </ul>
            

          </li>
          <li class="subnav_right">
          <ul id="subnav_fixed-trackme" class="subnav_fixed">

        
        
        
        

        <li class="blogItem">
          <a href="https://www.charlestoncitypaper.com/blogs/eat" target="_self">Eat</a>
        </li>

        


      
         
        
        
        

        <li class="">
          <a href="https://www.charlestoncitypaper.com/dish" target="_self">DISH dining guide</a>
        </li>

        


      
         
        
        
        

        <li class="">
          <a href="https://www.charlestoncitypaper.com/charleston/dirt/Category?oid=3628746" target="_self">DIRT local food guide</a>
        </li>

        


      
         
        
        
        

        <li class="">
          <a href="https://www.charlestoncitypaper.com/charleston/ArticleArchives?category=1072160" target="_self">Restaurant Reviews</a>
        </li>

        


      
         
        
        
        

        <li class="">
          <a href="https://www.charlestoncitypaper.com/charleston/swig-bar-guide/Category?oid=1072162" target="_self">SWIG bar guide</a>
        </li>

        


      
         
        
        
        

        <li class="">
          <a href="https://www.charlestoncitypaper.com/charleston/EventSearch?eventSection=1069891" target="_self">Cuisine Calendar</a>
        </li>

        


      
         
        
        
        

        <li class="searchItem">
          <a href="https://www.charlestoncitypaper.com/charleston/LocationSearch?locationSection=1063747&amp;locationCategory=&amp;diningFeature=&amp;neighborhood=&amp;keywords=&amp;sa=go" target="_self">Find a restaurant</a>
        </li>

        
          </ul> </li></ul> <!-- check 27 - Find a restaurant - 97 -->
        


      
         
          <h3 class="hidden">Browse Music+Clubs</h3>
          <ul class="subnav_content" id="SNAV_MusicClubs" style="display:none;">
           <li class="subnav_left">

          
          
               <ul id="subnav_headlines-trackme" class="subnav_headlines">






              

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/FeedbackFile/archives/2019/12/09/photos-little-stranger-and-friends-at-lo-fi-brewing">PHOTOS: Little Stranger and friends at Lo-Fi Brewing<img src="https://www.charlestoncitypaper.com/images/home/new.gif"></a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/FeedbackFile/archives/2019/12/07/sc-hip-hop-artist-benny-starr-is-endorsing-elizabeth-warren-for-president">S.C. hip-hop artist Benny Starr is endorsing Elizabeth Warren for president</a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/FeedbackFile/archives/2019/12/07/forte-jazz-lounge-is-throwing-a-frank-sinatra-birthday-bash-on-dec-12">Forte Jazz Lounge is throwing a Frank Sinatra birthday bash on Dec. 12</a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/FeedbackFile/archives/2019/12/06/punk-flea-market-stops-to-vend-vinyl-taxidermy-bizarreness-at-the-sparrow-this-weekend">Punk Flea Market stops to vend vinyl, taxidermy, bizarreness at the Sparrow this weekend</a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/FeedbackFile/archives/2019/12/06/crab-claw-reports-being-emotionally-affected-by-coach-ron-riveras-ouster-from-carolina-panthers">Crab Claw reports being "emotionally affected" by coach Ron Rivera's ouster from Carolina Panthers</a>
             </li>

            
          

              
              
              

             <li class="subnavHeadlines last">
               <a href="https://www.charlestoncitypaper.com/FeedbackFile/archives/2019/12/05/mike-l-ves-latest-album-comes-wired-into-a-special-hoodie">MiKE L!VE's latest album comes wired into a special hoodie</a>
             </li>

            
          
               <li id="navigationmorelink">
               <a href="https://www.charlestoncitypaper.com/gyrobase/ArticleArchives?section=oid:1072086">{ more }</a>
               </li>
            </ul>
            

          </li>
          <li class="subnav_right">
          <ul id="subnav_fixed-trackme" class="subnav_fixed">

        
        
        
        

        <li class="blogItem">
          <a href="https://www.charlestoncitypaper.com/blogs/feedbackfile" target="_self">Feedback File</a>
        </li>

        


      
         
        
        
        

        <li class="searchItem">
          <a href="https://www.charlestoncitypaper.com/charleston/EventSearch?eventSection=1067887&amp;eventCategory=1069927" target="_self">Find live music</a>
        </li>

        


      
         
        
        
        

        <li class="">
          <a href="https://www.charlestoncitypaper.com/charleston/LocationSearch?locationCategory=1067911" target="_self">Music venues</a>
        </li>

        


      
         
        
        
        

        <li class="">
          <a href="https://www.charlestoncitypaper.com/charleston/Events/AddEvent" target="_self">Submit a music event</a>
        </li>

        
          </ul> </li></ul> <!-- check 31 - Submit a music event - 6 -->
        


      

        
      </div>

    </div>
  </div>
</div>


    <!-- Header Ends Here -->
  `;

  const url = getGeneratedPageURL({
    html: html,
    css: "",
    js: ""
  });

  // const iframe = document.querySelector('#iframe')
  // iframe.src = url

  const classes = useStyles();
  return <div dangerouslySetInnerHTML={{ __html: html }}></div>;

  // return (<div className={classes.iframeOuterDiv}>
  //       <iframe  className={classes.mainIframe} src={url}  height="100%"   width="100%"/>
  //   </div>)
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
