import Cookies from "js-cookie";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

const processBallotId = async (props) => {
  let ballot_id = undefined;
  const getBallotIdFromWebsite = async () => {
    let axios_path_to =
      process.env.REACT_APP_BACK_END_SERVER_URL + "/server/v1/websites/";

    // await axios.get(axios_path_to).then(
    //   (response) => {
    //     if (response.status === 200) {
    //       let existingWebsite = response.data.find(
    //         (website) => website.url.search(window.location.host) > -1
    //       );
    //       console.log("existing website");
    //       console.log(existingWebsite);
    //       ballot_id = existingWebsite ? existingWebsite.ballot_id : undefined;
    //     } else {
    //       console.log(response.status);
    //     }
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );
    let tempWebsites = await axios.get(axios_path_to);

    let websiteExists = tempWebsites.data.find(
      (website) => website.url.search(window.location.host) > -1
    );

    ballot_id = websiteExists ? websiteExists.ballot_id : undefined;
  };

  let admin_ballot_id =
    props &&
    props.match &&
    props.match.params &&
    props.match.params.adminBallotId;

  if (admin_ballot_id || Cookies.get("from_admin") == "true") {
    admin_ballot_id && Cookies.set("admin_ballot_id", admin_ballot_id);
    Cookies.set("from_admin", "true");
    ballot_id = admin_ballot_id
      ? admin_ballot_id
      : Cookies.get("admin_ballot_id");
  } else {
    await getBallotIdFromWebsite();
    Cookies.set("from_admin", "false");

    // if (window.location.host == "localhost:3000") {
    //   Cookies.set("from_admin", "false");
    //   ballot_id = "5e40d02cc62b214367f8422f";
    // } else if (
    //   window.location.host == "bestofcharleston.net" ||
    //   window.location.host == "www.bestofcharleston.net"
    // ) {
    //   Cookies.set("from_admin", "false");
    //   ballot_id = "5e40d02cc62b214367f8422f";
    // } else if (window.location.host == "bestofkc.kansascitymag.com") {
    //   Cookies.set("from_admin", "false");
    //   ballot_id = "5ebc94cd304dd80b87b97046";
    // } else if (
    //   window.location.host == "bop.wweek.com" ||
    //   window.location.host == "www.bop.wweek.com"
    // ) {
    //   Cookies.set("from_admin", "false");
    //   ballot_id = "5ec53a0ca4644f4eeb8ade57";
    // } else if (
    //   window.location.host == "bestof.sandiegoreader.com" ||
    //   window.location.host == "www.bestof.sandiegoreader.com"
    // ) {
    //   Cookies.set("from_admin", "false");
    //   ballot_id = "5eea4f2f2ab28b544e0febd2";
    // } else {
    //   Cookies.set("from_admin", "false");
    //   ballot_id = undefined;
    // }
  }

  return ballot_id;
};

export default processBallotId;
