/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import Cookies from "js-cookie";
import firebase from "firebase/app";
import "firebase/auth";

import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  Input,
  colors,
  Popper,
  InputLabel,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
  Select,
  MenuItem,
} from "@material-ui/core";

import InputIcon from "@material-ui/icons/Input";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import ContactsIcon from "@material-ui/icons/Contacts";

import axios from "utils/axios";
import useRouter from "utils/useRouter";
import { PricingModal, NotificationsPopover } from "components";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  logo: {
    width: "170px",
    height: "36px",
  },
  search: {
    backgroundColor: "rgba(255,255,255, 0.1)",
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: "inherit",
  },
  searchInput: {
    flexGrow: 1,
    color: "inherit",
    "& input::placeholder": {
      opacity: 1,
      color: "inherit",
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900],
    },
  },
  trialIcon: {
    marginRight: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  logoutButton: {
    marginLeft: theme.spacing(2),
  },
  contactButton: {
    marginLeft: theme.spacing(2),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
  customerName: {
    color: "white",
  },
  topBarSelect: {
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
  MuiSelectIcon: {
    color: "white !important",
  },
}));

const TopBar = (props) => {
  const { onOpenNavBarMobile, className, ...rest } = props;

  const classes = useStyles();
  const { history } = useRouter();
  const searchRef = useRef(null);
  const dispatch = useDispatch();
  const notificationsRef = useRef(null);
  const [pricingModalOpen, setPricingModalOpen] = useState(false);
  const [openSearchPopover, setOpenSearchPopover] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [customerName, setCustomerName] = useState();
  const [customerId, setCustomerId] = useState();
  const [memberData, setMemberData] = useState();
  const [customerData, setCustomerData] = useState();
  const [menuData, setMenuData] = useState();

  const updateCustomerAuthLastLogoutDetails = async () => {
    let customerAuthId = Cookies.get("customer_auth_id");

    if (customerAuthId) {
      const payload = {
        customerAuthId: customerAuthId,
      };

      let axios_path_to =
        process.env.REACT_APP_BACK_END_SERVER_URL +
        "/server/v1/customers_auth/updateCustomerAuthLastLogoutDetails";

      await axios.post(axios_path_to, payload).then(
        (response) => {
          if (response.status === 200) {
            return true;
          } else {
            router.history.push("/auth/login");
            return false;
          }
        },
        (error) => {
          console.log(error);
          return false;
        }
      );
    }
  };

  const handleLogout = async () => {
    await updateCustomerAuthLastLogoutDetails();
    Cookies.remove("ballot_name");
    Cookies.remove("guide_name");
    Cookies.remove("ballot_id");
    Cookies.remove("super_admin");
    Cookies.remove("customer_id");
    Cookies.remove("customer_auth_id");
    Cookies.remove("owner");
    Cookies.remove("org_owner_id");
    Cookies.remove("show_org_nav");
    Cookies.remove("previous_customer_id");
    Cookies.remove("previous_customer_name");
    Cookies.remove("stripe_connected_account_id");
    Cookies.remove("member_role");
    Cookies.remove("enable_winners_portal");
    Cookies.remove("connected_all_entries_to_google");

    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    window.Intercom("boot", {
      app_id: "p43ayu3j",
    });
    Cookies.remove("logged_using_fire_base");

    // enable regular logout
    history.push("/auth/login");

    //disable oidc logout
    // window.oidcUserManager
    //   .signoutRedirect()
    //   .then(function() {
    //     console.log("Redirecting to sign-out");
    //   })
    //   .catch(function(err) {
    //     console.error("Error during sign-out:", err);
    //   });
  };

  const router = useRouter();

  const handlePricingClose = () => {
    setPricingModalOpen(false);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const handleSearchPopverClose = () => {
    setOpenSearchPopover(false);
  };
  const dropdownMenuProps = {
    color: "white",
    border: "0px !important",
    borderBottom: "0px",
    icon: "white !important",
    padding: "0px",
  };
  const getRole = (customerId) => {
    if (memberData.orgs_belongs_to && memberData.orgs_belongs_to.length > 0) {
      let memberOrgIndex = memberData.orgs_belongs_to.findIndex(
        (data) => data.org_id == customerId
      );
      if (memberOrgIndex > -1) {
        let memberOrgRole = memberData.orgs_belongs_to[memberOrgIndex].role;
        if (memberOrgRole) {
          return memberOrgRole;
        } else {
          return memberData.role;
        }
      } else {
        return memberData.role;
      }
    } else {
      return memberData.role;
    }
  };

  const handleChange = (event) => {
    let customerId = event.target.value;

    let tempName = menuData.find(
      (data) => data.customer_id == event.target.value
    );
    let tempIndex = memberData.orgs_belongs_to.findIndex((data) => data.org_id);
    if (tempIndex < 0) {
      Cookies.set("previous_customer_id", customerData._id);
      Cookies.set("previous_customer_name", customerData.name);
    }

    setCustomerName(tempName);

    Cookies.set("customer_id", customerId);

    Cookies.set("member_role", getRole(customerId));

    router.history.push("/ballots");
    window.location.reload();
  };

  const popularSearches = [
    "Best of Portland 2020",
    "Best of Portland 2020",
    "Admin Pannel",
    "Project",
    "Pages",
  ];

  const calculateMenuData = (ownerInfo, memberInfo) => {
    let tempArray = [];
    let tempObj = {
      name: ownerInfo && ownerInfo.name,
      customer_id: ownerInfo && ownerInfo._id,
    };
    // if (Cookies.get("previous_customer_name")) {
    //   tempObj = {
    //     name: Cookies.get("previous_customer_name"),
    //     customer_id: Cookies.get("previous_customer_id"),
    //   };
    // } else {
    //   tempObj = { name: customerInfo.name, customer_id: customerInfo._id };
    // }

    tempArray.push(tempObj);
    memberInfo &&
      memberInfo.orgs_belongs_to &&
      memberInfo.orgs_belongs_to.length > 0 &&
      memberInfo.orgs_belongs_to.map((data) => {
        let tempObj = { name: data.name, customer_id: data.org_id };
        tempArray.push(tempObj);
      });
    return tempArray;
  };
  useEffect(() => {
    let mounted = true;

    let axios_path_to =
      process.env.REACT_APP_BACK_END_SERVER_URL +
      "/server/v1/customers/" +
      Cookies.get("customer_id");

    if (mounted) {
      axios.get(axios_path_to).then(
        (response) => {
          if (response.status === 200) {
            Cookies.set("customer_slug", response.data && response.data.slug);
            setCustomerData(response.data);
            setCustomerName(response.data && response.data.name);

            let axios_path_to2 =
              process.env.REACT_APP_BACK_END_SERVER_URL +
              "/server/v1/customers_auth/" +
              Cookies.get("member_id");
            axios.get(axios_path_to2).then(
              (response2) => {
                if (response2.status === 200) {
                  let axios_path_to3 =
                    process.env.REACT_APP_BACK_END_SERVER_URL +
                    "/server/v1/customers/" +
                    response2.data.owner_id;
                  axios.get(axios_path_to3).then(
                    (response3) => {
                      if (response3.status === 200) {
                        setMemberData(response2.data);
                        setMenuData(
                          calculateMenuData(response3.data, response2.data)
                        );
                      } else {
                        console.log(response3.status);
                      }
                    },
                    (error) => {
                      console.log(error);
                    }
                  );
                } else {
                  console.log(response2.status);
                }
              },
              (error) => {
                console.log(error);
              }
            );
          } else {
            console.log(response.status);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }

    return () => {
      mounted = false;
    };
  }, []);

  const handleContact = () => {
    window.Intercom("show");
  };

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="secondary"
    >
      {window.location.pathname.indexOf("/create-ad") < 0 &&
        window.location.pathname.indexOf("/edit-profile-link") < 0 &&
        window.location.pathname.indexOf("/multi-create-ad") < 0 &&
        window.location.pathname.indexOf("/create-group-ad") < 0 &&
        window.location.pathname.indexOf("/multi-create-group-ad") < 0 &&
        window.location.pathname.indexOf(
          "/create-winners-upgraded-listing-ad"
        ) < 0 &&
        window.location.pathname.indexOf("/multi-create-winners-ad") < 0 &&
        window.location.pathname.indexOf("/multi-create-winners-group-ad") <
          0 && (
          <>
            {" "}
            <Toolbar>
              <RouterLink to="/">
                <img
                  alt="Logo"
                  className={classes.logo}
                  src="/images/logos/Logo_WhiteSmall.png"
                />
              </RouterLink>
              <div className={classes.flexGrow} />
              <Hidden smDown>
                <Popper
                  anchorEl={searchRef.current}
                  className={classes.searchPopper}
                  open={openSearchPopover}
                  transition
                >
                  <ClickAwayListener onClickAway={handleSearchPopverClose}>
                    <Paper
                      className={classes.searchPopperContent}
                      elevation={3}
                    >
                      <List>
                        {popularSearches.map((search) => (
                          <ListItem
                            button
                            key={search}
                            onClick={handleSearchPopverClose}
                          >
                            <ListItemIcon>
                              <SearchIcon />
                            </ListItemIcon>
                            <ListItemText primary={search} />
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </Hidden>
              <Hidden mdDown>
                {Cookies.get("super_admin") != "true" && (
                  <>
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.customerName}
                    >
                      {customerName}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={customerId}
                      onChange={handleChange}
                      dropDownMenuProps={dropdownMenuProps}
                      disableUnderline
                      className={classes.topBarSelect}
                    >
                      {menuData &&
                        menuData.map((data) => (
                          <MenuItem value={data.customer_id}>
                            {data.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </>
                )}
                <Button
                  className={classes.contactButton}
                  color="inherit"
                  onClick={handleContact}
                >
                  <ContactsIcon className={classes.contactIcon} />
                  Contact
                </Button>
                <Button
                  className={classes.logoutButton}
                  color="inherit"
                  onClick={handleLogout}
                >
                  <InputIcon className={classes.logoutIcon} />
                  Sign out
                </Button>
              </Hidden>
              <Hidden lgUp>
                <IconButton color="inherit" onClick={onOpenNavBarMobile}>
                  <MenuIcon />
                </IconButton>
              </Hidden>
            </Toolbar>
            <PricingModal
              onClose={handlePricingClose}
              open={pricingModalOpen}
            />
            <NotificationsPopover
              anchorEl={notificationsRef.current}
              notifications={notifications}
              onClose={handleNotificationsClose}
              open={openNotifications}
            />{" "}
          </>
        )}
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
