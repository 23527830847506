import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Button, CircularProgress } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  progressButton: {
    width: "200px !important",
    height: "40px !important",
  },
}));

const ProgressButton = (props) => {
  const classes = useStyles();
  const { title, to, inProcess, onClick, variant, color, width } = props;
  const [parentSize, setParentSize] = useState(0);
  const parentRef = useRef(null);
  useEffect(() => {
    const { clientHeight, clientWidth } = parentRef.current;

    setParentSize(Math.min(clientHeight, clientWidth));
  }, []);

  return (
    <Button
      ref={parentRef}
      variant={variant}
      component={RouterLink}
      to={to}
      onClick={onClick}
      color={color}
      className={classes.progressButton}
    >
      {inProcess ? (
        <CircularProgress
          className={classes.progress}
          size={0.8 * parentSize}
        />
      ) : (
        title
      )}
    </Button>
  );
};

ProgressButton.propTypes = {
  className: PropTypes.string,
};

export default ProgressButton;
