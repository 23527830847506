/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

import AuthLayout from "./layouts/Auth";
import VoterLayout from "./layouts/Voter";
import ErrorLayout from "./layouts/Error";
import DashboardLayout from "./layouts/Dashboard";

import withAuth from "../src/hoc/withAuth";
import withPrivate from "../src/hoc/withPrivate";
import withKeenTrack from "../src/hoc/withKeen";
import Cookies from "js-cookie";
import LoginOidc from "views/LoginOidc/LoginOidc";

const routes = [
  {
    path: "/",
    exact: true,
    component: () => {
      if (Cookies.get("super_admin") === "true") {
        return <Redirect to="/all-customers" />;
      } else {
        return <Redirect to="/ballots" />;
      }
    },
  },
  {
    path: "/auth",
    component: AuthLayout,
    routes: [
      {
        path: "/auth/login",
        exact: true,
        component: lazy(() => import("views/Login")),
      },
      {
        path: "/auth/login-oidc",
        exact: true,
        component: lazy(() => import("views/LoginOidc")),
      },
      {
        path: "/auth/forgotpassword",
        exact: true,
        component: lazy(() => import("views/Forgotpassword")),
      },
      {
        path: "/auth/loginresetpassword/:id",
        exact: true,
        component: lazy(() => import("views/LoginResetpassword")),
      },
      {
        path: "/auth/register",
        exact: true,
        component: lazy(() => import("views/Register")),
      },
      {
        path: "/auth/register/:id",
        exact: true,
        component: lazy(() => import("views/RegisterUpdate")),
      },
      {
        path: "/auth/reset-password/:id",
        exact: true,
        component: lazy(() => import("views/ResetPassword")),
      },
      {
        path: "/auth/contact-sales/",
        exact: true,
        component: lazy(() => import("views/ContactSales")),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: "*/photo-ballot",
    component: DashboardLayout,
    routes: [
      {
        path: "*/photo-ballot/ballots",
        exact: true,
        component: withAuth(lazy(() => import("views/PhotoBallot/Ballots"))),
      },
      {
        path: "*/photo-ballot/ballots/create",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/BallotsCreate"))
        ),
      },
      {
        path: "*/photo-ballot/ballots/clone",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/BallotConvert"))
        ),
      },
      {
        path: "*/photo-ballot/ballots/edit/:id",
        exact: true,
        component: withAuth(lazy(() => import("views/PhotoBallot/BallotEdit"))),
      },
      {
        path: "*/photo-ballot/ballotoverview/",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/BallotOverview"))
        ),
      },
      {
        path: "*/photo-ballot/ballotoverview/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/BallotOverview"))
        ),
      },
      {
        path: "*/photo-ballot/categories",
        exact: true,
        component: withAuth(lazy(() => import("views/PhotoBallot/Categories"))),
      },

      {
        path: "*/photo-ballot/categories/:id",
        exact: true,
        component: withAuth(lazy(() => import("views/PhotoBallot/Categories"))),
      },
      {
        path: "*/photo-ballot/category/create/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/CategoryCreate"))
        ),
      },
      {
        path: "*/photo-ballot/category/edit/:id/:id2",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/CategoryEdit"))
        ),
      },
      {
        path: "*/photo-ballot/category/sub-categories/:id/:id1",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/SubCategories"))
        ),
      },
      {
        path: "*/photo-ballot/sub-category/edit",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/SubCategoryEdit"))
        ),
      },
      {
        path: "*/photo-ballot/sub-category/add/:id/:id1",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/SubCategoryAdd"))
        ),
      },
      {
        path: "*/photo-ballot/sub-category/edit/:id/:id1/:id2",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/SubCategoryEdit"))
        ),
      },

      {
        path: "*/photo-ballot/publish/",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/PublishSettings"))
        ),
      },
      {
        path: "*/photo-ballot/publish/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/PublishSettings"))
        ),
      },
      {
        path: "*/photo-ballot/exports/",
        exact: true,
        component: withAuth(lazy(() => import("views/PhotoBallot/Exports"))),
      },

      {
        path: "*/photo-ballot/exports/:id",
        exact: true,
        component: withAuth(lazy(() => import("views/PhotoBallot/Exports"))),
      },
      {
        path: "*/photo-ballot/super-settings/",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/SuperSettings"))
        ),
      },
      {
        path: "*/photo-ballot/super-settings/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/SuperSettings"))
        ),
      },
      {
        path: "*/photo-ballot/imports/",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/ImportsSettings"))
        ),
      },
      {
        path: "*/photo-ballot/imports-finallist/",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/ImportsFinalListSettings"))
        ),
      },
      {
        path: "*/photo-ballot/imports-finallist/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/ImportsFinalListSettings"))
        ),
      },

      {
        path: "*/photo-ballot/all-customers/",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/AllCustomers"))
        ),
      },
      {
        path: "*/photo-ballot/clone-ballot/",
        exact: true,
        component: lazy(() => import("views/PhotoBallot/CloneBallot")),
      },
      {
        path: "*/photo-ballot/super-users/",
        exact: true,
        component: lazy(() => import("views/PhotoBallot/SuperUsers")),
      },
      {
        path: "*/photo-ballot/system-settings/",
        exact: true,
        component: lazy(() => import("views/PhotoBallot/SystemSettings")),
      },
      {
        path: "*/photo-ballot/map-website-to-ballot-path/",
        exact: true,
        component: lazy(() => import("views/PhotoBallot/MapWebsiteToBallot")),
      },
      {
        path: "*/photo-ballot/map-website-to-guide/",
        exact: true,
        component: lazy(() =>
          import("views/PhotoBallot/WinnersPortal/MapWebsiteToGuide")
        ),
      },

      {
        path: "*/photo-ballot/imports/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/ImportsSettings"))
        ),
      },
      {
        path: "*/photo-ballot/remove-duplicates/",
        exact: true,
        component: lazy(() => import("views/PhotoBallot/RemoveDuplicates")),
      },
      {
        path: "*/photo-ballot/remove-duplicates/:id",
        exact: true,
        component: lazy(() => import("views/PhotoBallot/RemoveDuplicates")),
      },

      {
        path: "*/photo-ballot/calendar",
        exact: true,
        component: lazy(() => import("views/PhotoBallot/Calendar")),
      },

      {
        path: "*/photo-ballot/votes/overview",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/VotesOverview"))
        ),
      },
      {
        path: "*/photo-ballot/people/analytics",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/PeopleAnalytics"))
        ),
      },
      {
        path: "*/photo-ballot/people/analyticsByVoters",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/PeopleAnalyticsByVoters"))
        ),
      },
      {
        path: "*/photo-ballot/all-voters",
        exact: true,
        component: withAuth(lazy(() => import("views/PhotoBallot/AllVoters"))),
      },
      {
        path: "*/photo-ballot/audience",
        exact: true,
        component: withAuth(lazy(() => import("views/PhotoBallot/Audience"))),
      },
      {
        path: "*/photo-ballot/emails",
        exact: true,
        component: withAuth(lazy(() => import("views/PhotoBallot/Emails"))),
      },
      {
        path: "*/photo-ballot/emails/new",
        exact: true,
        component: withAuth(lazy(() => import("views/PhotoBallot/Emails"))),
      },
      {
        path: "*/photo-ballot/email_link",
        exact: true,
        component: withAuth(lazy(() => import("views/PhotoBallot/EmailLink"))),
      },
      {
        path: "*/photo-ballot/email-link/:id",
        exact: true,
        component: withAuth(lazy(() => import("views/PhotoBallot/EmailLink"))),
      },
      {
        path: "*/photo-ballot/audience-new-email",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/AudienceNewEmail"))
        ),
      },

      {
        path: "*/photo-ballot/votes/in-depth-view/:id1/:id2/:id3/:id4",
        exact: true,
        component: lazy(() => import("views/PhotoBallot/VotesInDepthView")),
      },

      {
        path: "*/photo-ballot/settings",
        exact: true,
        component: withAuth(lazy(() => import("views/PhotoBallot/Settings"))),
      },
      {
        path: "*/photo-ballot/settings/:id",
        exact: true,
        component: withAuth(lazy(() => import("views/PhotoBallot/Settings"))),
      },

      {
        path: "*/photo-ballot/settings/:id/:tab",
        exact: true,
        component: withAuth(lazy(() => import("views/PhotoBallot/Settings"))),
      },
      {
        path: "*/photo-ballot/organization-settings",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/OrganizationSettings"))
        ),
      },
      {
        path: "*/photo-ballot/organization-settings/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/OrganizationSettings"))
        ),
      },

      {
        path: "*/photo-ballot/organization-settings/:id/:tab",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/OrganizationSettings"))
        ),
      },
      {
        path: "*/photo-ballot/organization-settings/:id/:tab/:tab2",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/OrganizationSettings"))
        ),
      },
      {
        path: "*/photo-ballot/ads-settings",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/AdsSettings"))
        ),
      },
      {
        path: "*/photo-ballot/ads-settings/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/AdsSettings"))
        ),
      },
      {
        path: "*/photo-ballot/ads-settings/:id/:tab",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/AdsSettings"))
        ),
      },
      {
        path: "*/photo-ballot/ads-settings/:id/:tab/:editLinkId",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/AdsSettings"))
        ),
      },
      {
        path: "*/photo-ballot/ads-settings/:tab",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/AdsSettings"))
        ),
      },
      {
        path: "*/photo-ballot/group-ads",
        exact: true,
        component: withAuth(lazy(() => import("views/PhotoBallot/GroupAds"))),
      },
      {
        path: "*/photo-ballot/group-ads/:id/:id2",
        exact: true,
        component: withAuth(lazy(() => import("views/PhotoBallot/GroupAds"))),
      },
      {
        path: "*/photo-ballot/group-ads/:id/:id2/:editLinkId",
        exact: true,
        component: withAuth(lazy(() => import("views/PhotoBallot/GroupAds"))),
      },
      {
        path: "*/photo-ballot/social-feed",
        exact: true,
        component: lazy(() => import("views/PhotoBallot/SocialFeed")),
      },
      {
        path: "*/photo-ballot/create-ad/",
        exact: true,
        component: lazy(() => import("views/PhotoBallot/CreateAd")),
      },
      {
        path: "*/photo-ballot/create-ad/:id",
        exact: true,
        component: lazy(() => import("views/PhotoBallot/CreateAd")),
      },
      {
        path: "*/photo-ballot/multi-create-ad/",
        exact: true,
        component: lazy(() => import("views/PhotoBallot/MultiCreateAd")),
      },
      {
        path: "*/photo-ballot/multi-create-ad/:id",
        exact: true,
        component: lazy(() => import("views/PhotoBallot/MultiCreateAd")),
      },
      {
        path: "*/photo-ballot/create-group-ad/",
        exact: true,
        component: lazy(() => import("views/PhotoBallot/CreateGroupAd")),
      },
      {
        path: "*/photo-ballot/create-group-ad/:id",
        exact: true,
        component: lazy(() => import("views/PhotoBallot/CreateGroupAd")),
      },
      {
        path: "*/photo-ballot/multi-create-group-ad/",
        exact: true,
        component: lazy(() => import("views/PhotoBallot/MultiCreateGroupAd")),
      },
      {
        path: "*/photo-ballot/multi-create-group-ad/:id",
        exact: true,
        component: lazy(() => import("views/PhotoBallot/MultiCreateGroupAd")),
      },
      {
        path: "*/photo-ballot/create-winners-upgraded-listing-ad/",
        exact: true,
        component: lazy(() =>
          import("views/PhotoBallot/CreateWinnersUpgradedListingAd")
        ),
      },
      {
        path: "*/photo-ballot/create-winners-upgraded-listing-ad/:id",
        exact: true,
        component: lazy(() =>
          import("views/PhotoBallot/CreateWinnersUpgradedListingAd")
        ),
      },
      {
        path: "*/photo-ballot/multi-create-winners-ad/",
        exact: true,
        component: lazy(() =>
          import("views/PhotoBallot/WinnersPortal/MultiCreateWinnersAd")
        ),
      },
      {
        path: "*/photo-ballot/multi-create-winners-ad/:id",
        exact: true,
        component: lazy(() =>
          import("views/PhotoBallot/WinnersPortal/MultiCreateWinnersAd")
        ),
      },
      {
        path: "*/photo-ballot/multi-create-winners-group-ad/",
        exact: true,
        component: lazy(() =>
          import("views/PhotoBallot/WinnersPortal/MultiCreateWinnersGroupAd")
        ),
      },
      {
        path: "*/photo-ballot/multi-create-winners-group-ad/:id",
        exact: true,
        component: lazy(() =>
          import("views/PhotoBallot/WinnersPortal/MultiCreateWinnersGroupAd")
        ),
      },
      {
        path: "*/photo-ballot/multi-create-winners-group-ad/",
        exact: true,
        component: lazy(() =>
          import("views/PhotoBallot/WinnersPortal/MultiCreateWinnersGroupAd")
        ),
      },
      {
        path: "*/photo-ballot/multi-create-winners-group-ad/:id",
        exact: true,
        component: lazy(() =>
          import("views/PhotoBallot/WinnersPortal/MultiCreateWinnersGroupAd")
        ),
      },
      {
        path: "*/photo-ballot/edit-profile-link/",
        exact: true,
        component: lazy(() => import("views/PhotoBallot/EditProfileLink")),
      },
      {
        path: "*/photo-ballot/edit-profile-link/:id",
        exact: true,
        component: lazy(() => import("views/PhotoBallot/EditProfileLink")),
      },

      {
        path: "*/photo-ballot/choose-admin/",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/ChooseAdmin"))
        ),
      },

      {
        path: "*/photo-ballot/winners-portal/guides/:customerId",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/Guides"))
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/guides",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/Guides"))
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/new-guide",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/NewGuide"))
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/edit-guide/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/EditGuide"))
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/general-settings",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/GeneralSettings"))
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/general-settings/:id/:tab",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/GeneralSettings"))
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/general-settings/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/GeneralSettings"))
        ),
      },

      {
        path: "*/photo-ballot/winners-portal/publish-settings",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/PublishSettings"))
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/imports/",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/ImportsSettings"))
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/imports/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/ImportsSettings"))
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/exports/",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/ExportsSettings"))
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/exports/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/ExportsSettings"))
        ),
      },
      {
        path:
          "/winners-portal/listing-profile/:group_id/:category_id/:profile_id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/ListingProfile"))
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/editlisting-profile/:id",
        exact: true,
        component: withAuth(
          lazy(() =>
            import("views/PhotoBallot/WinnersPortal/EditListingProfile")
          )
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/publish-settings/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/PublishSettings"))
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/ads-settings",
        exact: true,
        component: withAuth(
          lazy(() =>
            import("views/PhotoBallot/WinnersPortal/WinnersAdsSettings")
          )
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/ads-settings/:id",
        exact: true,
        component: withAuth(
          lazy(() =>
            import("views/PhotoBallot/WinnersPortal/WinnersAdsSettings")
          )
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/ads-settings/:id/:tab",
        exact: true,
        component: withAuth(
          lazy(() =>
            import("views/PhotoBallot/WinnersPortal/WinnersAdsSettings")
          )
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/ads-settings/:id/:tab/:editLinkId",
        exact: true,
        component: withAuth(
          lazy(() =>
            import("views/PhotoBallot/WinnersPortal/WinnersAdsSettings")
          )
        ),
      },

      {
        path: "*/photo-ballot/winners-portal/categories",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/Categories"))
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/categories/:id/:tab",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/Categories"))
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/category/create/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/CategoryCreate"))
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/category/edit/:id/:id2",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/CategoryEdit"))
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/category/sub-categories/:id/:id1",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/SubCategories"))
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/sub-category/add/:id/:id1",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/SubCategoryAdd"))
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/sub-category/edit/:id/:id1/:id2",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/SubCategoryEdit"))
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/super-settings",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/SuperSettings"))
        ),
      },
      {
        path: "*/photo-ballot/winners-portal/super-settings/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/SuperSettings"))
        ),
      },
      {
        path: "*/photo-ballot/choose-admin/",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PhotoBallot/WinnersPortal/ChooseAdmin"))
        ),
      },
      {
        path: "*/photo-ballot/tina-cms-demo/",
        exact: true,
        component: lazy(() => import("views/PhotoBallot/TinaCMSDemo")),
      },
      {
        path: "*/photo-ballot/tina-cms-demo/edit-page",
        exact: true,
        component: lazy(() => import("views/PhotoBallot/TinaCMSDemoEditPage")),
      },
      {
        path: "*/photo-ballot/email-demo/",
        exact: true,
        component: lazy(() => import("views/PhotoBallot/EmailDemo")),
      },
    ],
  },
  {
    path: "*/voter",
    component: VoterLayout,
    routes: [
      {
        path: "*/voter/index",
        exact: true,
        component: withPrivate(
          withKeenTrack(lazy(() => import("views/Voter")))
        ),
      },
      {
        path: "*/voter/index/:adminBallotId",
        exact: true,
        component: withPrivate(
          withKeenTrack(lazy(() => import("views/Voter")))
        ),
      },
      {
        path: "*/voter/sub-categories",
        exact: true,
        component: withPrivate(
          withKeenTrack(lazy(() => import("views/VoterSubCategories")))
        ),
      },

      {
        path: "*/voter/category-landing/:id",
        exact: true,
        component: withPrivate(
          withKeenTrack(lazy(() => import("views/VoterCategoryLanding")))
        ),
      },
      {
        path: "*/voter/sub-category-voting",
        exact: true,
        component: withPrivate(
          withKeenTrack(lazy(() => import("views/SubCategoryVoting")))
        ),
      },
      {
        path: "*/voter/sub-category-voting/:id/:id2",
        exact: true,
        component: withPrivate(
          withKeenTrack(lazy(() => import("views/SubCategoryVoting")))
        ),
      },
      {
        path: "*/voter/chose-voting-category",
        exact: true,
        component: withPrivate(
          withKeenTrack(lazy(() => import("views/ChoseVotingCategory")))
        ),
      },
      {
        path: "*/voter/register",
        exact: true,
        component: lazy(() => import("views/VoterRegister")),
      },
      {
        path: "*/voter/register/:id",
        exact: true,
        component: lazy(() => import("views/VoterRegister")),
      },
      {
        path: "/auth/register",
        exact: true,
        component: () => import("views/Register"),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: "/errors",
    component: ErrorLayout,
    routes: [
      {
        path: "/errors/error-401",
        exact: true,
        component: lazy(() => import("views/Error401")),
      },
      {
        path: "/errors/error-404",
        exact: true,
        component: lazy(() => import("views/Error404")),
      },
      {
        path: "/errors/error-500",
        exact: true,
        component: lazy(() => import("views/Error500")),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },

  {
    route: "*",
    component: DashboardLayout,
    routes: [
      {
        path: "/campaign/create",
        exact: true,
        component: withAuth(lazy(() => import("views/CreateCompaign"))),
      },
      //signin-callback.html
      // {
      //   path: "/signin-callback.html",
      //   exact: true,
      //   component: withAuth(lazy(() => import("views/LoginOidc"))),
      // },
      {
        path: "/ballots",
        exact: true,
        component: withAuth(lazy(() => import("views/Ballots"))),
      },
      {
        path: "/ballots/create",
        exact: true,
        component: withAuth(lazy(() => import("views/BallotsCreate"))),
      },
      {
        path: "/ballots/clone",
        exact: true,
        component: withAuth(lazy(() => import("views/BallotConvert"))),
      },
      {
        path: "/ballots/edit/:id",
        exact: true,
        component: withAuth(lazy(() => import("views/BallotEdit"))),
      },
      {
        path: "/ballotoverview/",
        exact: true,
        component: withAuth(lazy(() => import("views/BallotOverview"))),
      },
      {
        path: "/ballotoverview/:id",
        exact: true,
        component: withAuth(lazy(() => import("views/BallotOverview"))),
      },
      {
        path: "/categories",
        exact: true,
        component: withAuth(lazy(() => import("views/Categories"))),
      },

      {
        path: "/categories/:id",
        exact: true,
        component: withAuth(lazy(() => import("views/Categories"))),
      },
      {
        path: "/category/create/:id",
        exact: true,
        component: withAuth(lazy(() => import("views/CategoryCreate"))),
      },
      {
        path: "/category/edit/:id/:id2",
        exact: true,
        component: withAuth(lazy(() => import("views/CategoryEdit"))),
      },
      {
        path: "/category/sub-categories/:id/:id1",
        exact: true,
        component: withAuth(lazy(() => import("views/SubCategories"))),
      },
      {
        path: "/sub-category/edit",
        exact: true,
        component: withAuth(lazy(() => import("views/SubCategoryEdit"))),
      },
      {
        path: "/sub-category/add/:id/:id1",
        exact: true,
        component: withAuth(lazy(() => import("views/SubCategoryAdd"))),
      },
      {
        path: "/sub-category/edit/:id/:id1/:id2",
        exact: true,
        component: withAuth(lazy(() => import("views/SubCategoryEdit"))),
      },

      {
        path: "/publish/",
        exact: true,
        component: withAuth(lazy(() => import("views/PublishSettings"))),
      },
      {
        path: "/publish/:id",
        exact: true,
        component: withAuth(lazy(() => import("views/PublishSettings"))),
      },
      {
        path: "/exports/",
        exact: true,
        component: withAuth(lazy(() => import("views/Exports"))),
      },

      {
        path: "/exports/:id",
        exact: true,
        component: withAuth(lazy(() => import("views/Exports"))),
      },
      {
        path: "/super-settings/",
        exact: true,
        component: withAuth(lazy(() => import("views/SuperSettings"))),
      },
      {
        path: "/super-settings/:id",
        exact: true,
        component: withAuth(lazy(() => import("views/SuperSettings"))),
      },
      {
        path: "/imports/",
        exact: true,
        component: withAuth(lazy(() => import("views/ImportsSettings"))),
      },
      {
        path: "/imports-finallist/",
        exact: true,
        component: withAuth(
          lazy(() => import("views/ImportsFinalListSettings"))
        ),
      },
      {
        path: "/imports-finallist/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/ImportsFinalListSettings"))
        ),
      },
      {
        path: "/super-dashboard/",
        exact: true,
        component: withAuth(lazy(() => import("views/SuperDashboard"))),
      },
      {
        path: "/all-organizations/",
        exact: true,
        component: withAuth(lazy(() => import("views/AllOrganizations"))),
      },
      {
        path: "/all-ballots/",
        exact: true,
        component: withAuth(lazy(() => import("views/AllBallots"))),
      },
      {
        path: "/all-orders/",
        exact: true,
        component: withAuth(lazy(() => import("views/AllOrders"))),
      },

      {
        path: "/all-customers/",
        exact: true,
        component: withAuth(lazy(() => import("views/AllCustomers"))),
      },
      {
        path: "/clone-ballot/",
        exact: true,
        component: lazy(() => import("views/CloneBallot")),
      },
      {
        path: "/super-users/",
        exact: true,
        component: lazy(() => import("views/SuperUsers")),
      },
      {
        path: "/system-settings/",
        exact: true,
        component: lazy(() => import("views/SystemSettings")),
      },
      {
        path: "/map-website-to-ballot-path/",
        exact: true,
        component: lazy(() => import("views/MapWebsiteToBallot")),
      },
      {
        path: "/map-website-to-guide/",
        exact: true,
        component: lazy(() => import("views/WinnersPortal/MapWebsiteToGuide")),
      },

      {
        path: "/imports/:id",
        exact: true,
        component: withAuth(lazy(() => import("views/ImportsSettings"))),
      },
      {
        path: "/remove-duplicates/",
        exact: true,
        component: lazy(() => import("views/RemoveDuplicates")),
      },
      {
        path: "/remove-duplicates/:id",
        exact: true,
        component: lazy(() => import("views/RemoveDuplicates")),
      },

      {
        path: "/calendar",
        exact: true,
        component: lazy(() => import("views/Calendar")),
      },

      {
        path: "/votes/overview",
        exact: true,
        component: withAuth(lazy(() => import("views/VotesOverview"))),
      },
      {
        path: "/people/analytics",
        exact: true,
        component: withAuth(lazy(() => import("views/PeopleAnalytics"))),
      },
      {
        path: "/people/analyticsByVoters",
        exact: true,
        component: withAuth(
          lazy(() => import("views/PeopleAnalyticsByVoters"))
        ),
      },
      {
        path: "/all-voters",
        exact: true,
        component: withAuth(lazy(() => import("views/AllVoters"))),
      },
      {
        path: "/registrations",
        exact: true,
        component: withAuth(lazy(() => import("views/Registrations"))),
      },
      {
        path: "/registration/in-depth-view/:id1",
        exact: true,
        component: lazy(() => import("views/RegistrationInDepthView")),
      },
      {
        path: "/audience",
        exact: true,
        component: withAuth(lazy(() => import("views/Audience"))),
      },
      {
        path: "/emails",
        exact: true,
        component: withAuth(lazy(() => import("views/Emails"))),
      },
      {
        path: "/emails/new",
        exact: true,
        component: withAuth(lazy(() => import("views/Emails"))),
      },
      {
        path: "/email_link",
        exact: true,
        component: withAuth(lazy(() => import("views/EmailLink"))),
      },
      {
        path: "/email-link/:id",
        exact: true,
        component: withAuth(lazy(() => import("views/EmailLink"))),
      },
      {
        path: "/audience-new-email",
        exact: true,
        component: withAuth(lazy(() => import("views/AudienceNewEmail"))),
      },

      {
        path: "/votes/in-depth-view/:id1/:id2/:id3/:id4",
        exact: true,
        component: lazy(() => import("views/VotesInDepthView")),
      },

      {
        path: "/settings",
        exact: true,
        component: withAuth(lazy(() => import("views/Settings"))),
      },
      {
        path: "/settings/:id",
        exact: true,
        component: withAuth(lazy(() => import("views/Settings"))),
      },

      {
        path: "/settings/:id/:tab",
        exact: true,
        component: withAuth(lazy(() => import("views/Settings"))),
      },
      {
        path: "/organization-settings",
        exact: true,
        component: withAuth(lazy(() => import("views/OrganizationSettings"))),
      },
      {
        path: "/organization-settings/:id",
        exact: true,
        component: withAuth(lazy(() => import("views/OrganizationSettings"))),
      },

      {
        path: "/organization-settings/:id/:tab",
        exact: true,
        component: withAuth(lazy(() => import("views/OrganizationSettings"))),
      },
      {
        path: "/organization-settings/:id/:tab/:tab2",
        exact: true,
        component: withAuth(lazy(() => import("views/OrganizationSettings"))),
      },
      {
        path: "/ads-settings",
        exact: true,
        component: withAuth(lazy(() => import("views/AdsSettings"))),
      },
      {
        path: "/ads-settings/:id",
        exact: true,
        component: withAuth(lazy(() => import("views/AdsSettings"))),
      },
      {
        path: "/ads-settings/:id/:tab",
        exact: true,
        component: withAuth(lazy(() => import("views/AdsSettings"))),
      },
      {
        path: "/ads-settings/:id/:tab/:editLinkId",
        exact: true,
        component: withAuth(lazy(() => import("views/AdsSettings"))),
      },
      {
        path: "/ads-settings/:tab",
        exact: true,
        component: withAuth(lazy(() => import("views/AdsSettings"))),
      },
      {
        path: "/group-ads",
        exact: true,
        component: withAuth(lazy(() => import("views/GroupAds"))),
      },
      {
        path: "/group-ads/:id/:id2",
        exact: true,
        component: withAuth(lazy(() => import("views/GroupAds"))),
      },
      {
        path: "/group-ads/:id/:id2/:editLinkId",
        exact: true,
        component: withAuth(lazy(() => import("views/GroupAds"))),
      },
      {
        path: "/social-feed",
        exact: true,
        component: lazy(() => import("views/SocialFeed")),
      },
      {
        path: "/create-ad/",
        exact: true,
        component: lazy(() => import("views/CreateAd")),
      },
      {
        path: "/create-ad/:id",
        exact: true,
        component: lazy(() => import("views/CreateAd")),
      },
      {
        path: "/multi-create-ad/",
        exact: true,
        component: lazy(() => import("views/MultiCreateAd")),
      },
      {
        path: "/multi-create-ad/:id",
        exact: true,
        component: lazy(() => import("views/MultiCreateAd")),
      },
      {
        path: "/create-group-ad/",
        exact: true,
        component: lazy(() => import("views/CreateGroupAd")),
      },
      {
        path: "/create-group-ad/:id",
        exact: true,
        component: lazy(() => import("views/CreateGroupAd")),
      },
      {
        path: "/multi-create-group-ad/",
        exact: true,
        component: lazy(() => import("views/MultiCreateGroupAd")),
      },
      {
        path: "/multi-create-group-ad/:id",
        exact: true,
        component: lazy(() => import("views/MultiCreateGroupAd")),
      },
      {
        path: "/create-winners-upgraded-listing-ad/",
        exact: true,
        component: lazy(() => import("views/CreateWinnersUpgradedListingAd")),
      },
      {
        path: "/create-winners-upgraded-listing-ad/:id",
        exact: true,
        component: lazy(() => import("views/CreateWinnersUpgradedListingAd")),
      },
      {
        path: "/multi-create-winners-ad/",
        exact: true,
        component: lazy(() =>
          import("views/WinnersPortal/MultiCreateWinnersAd")
        ),
      },
      {
        path: "/multi-create-winners-ad/:id",
        exact: true,
        component: lazy(() =>
          import("views/WinnersPortal/MultiCreateWinnersAd")
        ),
      },
      {
        path: "/multi-create-winners-group-ad/",
        exact: true,
        component: lazy(() =>
          import("views/WinnersPortal/MultiCreateWinnersGroupAd")
        ),
      },
      {
        path: "/multi-create-winners-group-ad/:id",
        exact: true,
        component: lazy(() =>
          import("views/WinnersPortal/MultiCreateWinnersGroupAd")
        ),
      },
      {
        path: "/multi-create-winners-group-ad/",
        exact: true,
        component: lazy(() =>
          import("views/WinnersPortal/MultiCreateWinnersGroupAd")
        ),
      },
      {
        path: "/multi-create-winners-group-ad/:id",
        exact: true,
        component: lazy(() =>
          import("views/WinnersPortal/MultiCreateWinnersGroupAd")
        ),
      },
      {
        path: "/edit-profile-link/",
        exact: true,
        component: lazy(() => import("views/EditProfileLink")),
      },
      {
        path: "/edit-profile-link/:id",
        exact: true,
        component: lazy(() => import("views/EditProfileLink")),
      },

      {
        path: "/choose-admin/",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/ChooseAdmin"))
        ),
      },

      {
        path: "/winners-portal/guides/:customerId",
        exact: true,
        component: withAuth(lazy(() => import("views/WinnersPortal/Guides"))),
      },
      {
        path: "/winners-portal/guides",
        exact: true,
        component: withAuth(lazy(() => import("views/WinnersPortal/Guides"))),
      },
      {
        path: "/winners-portal/new-guide",
        exact: true,
        component: withAuth(lazy(() => import("views/WinnersPortal/NewGuide"))),
      },
      {
        path: "/winners-portal/edit-guide/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/EditGuide"))
        ),
      },
      {
        path: "/winners-portal/general-settings",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/GeneralSettings"))
        ),
      },
      {
        path: "/winners-portal/general-settings/:id/:tab",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/GeneralSettings"))
        ),
      },
      {
        path: "/winners-portal/general-settings/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/GeneralSettings"))
        ),
      },

      {
        path: "/winners-portal/publish-settings",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/PublishSettings"))
        ),
      },
      {
        path: "/winners-portal/imports/",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/ImportsSettings"))
        ),
      },
      {
        path: "/winners-portal/imports/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/ImportsSettings"))
        ),
      },
      {
        path: "/winners-portal/exports/",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/ExportsSettings"))
        ),
      },
      {
        path: "/winners-portal/exports/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/ExportsSettings"))
        ),
      },
      {
        path:
          "/winners-portal/listing-profile/:group_id/:category_id/:profile_id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/ListingProfile"))
        ),
      },
      {
        path: "/winners-portal/editlisting-profile/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/EditListingProfile"))
        ),
      },
      {
        path: "/winners-portal/publish-settings/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/PublishSettings"))
        ),
      },
      {
        path: "/winners-portal/ads-settings",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/WinnersAdsSettings"))
        ),
      },
      {
        path: "/winners-portal/ads-settings/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/WinnersAdsSettings"))
        ),
      },
      {
        path: "/winners-portal/ads-settings/:id/:tab",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/WinnersAdsSettings"))
        ),
      },
      {
        path: "/winners-portal/ads-settings/:id/:tab/:editLinkId",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/WinnersAdsSettings"))
        ),
      },

      {
        path: "/winners-portal/categories",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/Categories"))
        ),
      },
      {
        path: "/winners-portal/categories/:id/:tab",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/Categories"))
        ),
      },
      {
        path: "/winners-portal/category/create/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/CategoryCreate"))
        ),
      },
      {
        path: "/winners-portal/category/edit/:id/:id2",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/CategoryEdit"))
        ),
      },
      {
        path: "/winners-portal/category/sub-categories/:id/:id1",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/SubCategories"))
        ),
      },
      {
        path: "/winners-portal/sub-category/add/:id/:id1",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/SubCategoryAdd"))
        ),
      },
      {
        path: "/winners-portal/sub-category/edit/:id/:id1/:id2",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/SubCategoryEdit"))
        ),
      },
      {
        path: "/winners-portal/super-settings",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/SuperSettings"))
        ),
      },
      {
        path: "/winners-portal/super-settings/:id",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/SuperSettings"))
        ),
      },
      {
        path: "/choose-admin/",
        exact: true,
        component: withAuth(
          lazy(() => import("views/WinnersPortal/ChooseAdmin"))
        ),
      },
      {
        path: "/tina-cms-demo/",
        exact: true,
        component: lazy(() => import("views/TinaCMSDemo")),
      },
      {
        path: "/tina-cms-demo/edit-page",
        exact: true,
        component: lazy(() => import("views/TinaCMSDemoEditPage")),
      },
      {
        path: "/email-demo/",
        exact: true,
        component: lazy(() => import("views/EmailDemo")),
      },

      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
];

export default routes;
