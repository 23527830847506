import React, { useState, useEffect } from "react";

import Cookies from "js-cookie";

import axios from "utils/axios";
import compareHashPassword from "utils/compareHashPassword";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import useRouter from "utils/useRouter";
import getBallotId from "utils/getBallotId";

const useStyles = makeStyles((theme) => ({
  root: {},
  outerDiv: {
    width: "50%",
    textAlign: "center",
    margin: "0 auto",
    padding: "100px",
  },
  outerForm: {
    margin: theme.spacing(4),
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
}));

const withPrivate = (BaseComponent) => (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const [ballotId, setBallotId] = useState();
  const [ballotData, setBallotData] = useState();
  const [password, setPassword] = useState("");
  const [originalPassword, setOriginalPassword] = useState("");
  const [allowAccess, setAllowAccess] = useState(false);
  const router = useRouter();

  getBallotId(props).then((id) => setBallotId(id));

  //let ballot_id = getBallotId(props);
  const handleChange = (event) => {
    setPassword(event.target.value);
  };
  const handleSubmit = () => {
    // console.log(password);
    // console.log(originalPassword);
    if (compareHashPassword(password, originalPassword)) {
      Cookies.set("ballot_public_access", "true");
      setAllowAccess(true);
    } else {
      Cookies.set("ballot_public_access", "false");
      setAllowAccess(false);
    }
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      // if (window.location.host == "localhost:3000") {
      //   ballot_id = "5e78feef2fc27c0bfe2da607";
      // } else if (
      //   window.location.host == "bestofcharleston.net" ||
      //   window.location.host == "www.bestofcharleston.net"
      // ) {
      //   ballot_id = "5e40d02cc62b214367f8422f";
      // } else if (window.location.host == "bestofkc.kansascitymag.com") {
      //   ballot_id = "5ebc94cd304dd80b87b97046";
      // } else if (
      //   window.location.host == "bop.wweek.com" ||
      //   window.location.host == "www.bop.wweek.com"
      // ) {
      //   ballot_id = "5e419a04c62b214367f84231";
      // } else if (!props.match.params.id) {
      //   router.history.push("/auth/login");
      // }
      const axios_url =
        process.env.REACT_APP_BACK_END_SERVER_URL +
        "/server/v1/ballots/" +
        ballotId;
      ballotId &&
        axios.get(axios_url).then(
          (response) => {
            if (response.status === 200) {
              // console.log("from private ballot");
              // console.log(response.data);
              setOriginalPassword(
                response.data.publish_settings.privacy.private.password
              );
              setBallotData(response.data);
            } else {
              console.log(response);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
    return () => {
      mounted = false;
    };
  }, [ballotId]);
  if (ballotData && ballotData.publish_settings.privacy.public) {
    Cookies.set("ballot_public_access", "false");
    return <BaseComponent ballotId={ballotId} {...props} />;
  } else if (
    ballotData &&
    !ballotData.publish_settings.privacy.private.anyone_can_view &&
    !allowAccess &&
    (Cookies.get("ballot_public_access") == "false" ||
      !Cookies.get("ballot_public_access"))
  ) {
    Cookies.set("ballot_public_access", "false");
    return (
      <>
        {" "}
        <page>
          <div className={classes.outerDiv}>
            <div className={classes.fields}>
              <TextField
                fullWidth
                label="Password"
                name="password"
                onChange={handleChange}
                type="password"
                value={password}
                variant="outlined"
              />

              <Button
                color="secondary"
                size="large"
                type="submit"
                variant="contained"
                onClick={handleSubmit}
              >
                Allow Access
              </Button>
            </div>
          </div>
        </page>{" "}
      </>
    );
  } else if (
    ballotData &&
    !ballotData.publish_settings.privacy.private.anyone_can_view &&
    (allowAccess || Cookies.get("ballot_public_access") == "true")
  ) {
    Cookies.set("ballot_public_access", "true");
    return <BaseComponent ballotId={ballotId} {...props} />;
  } else if (
    ballotData &&
    ballotData.publish_settings.privacy.private.anyone_can_view
  ) {
    Cookies.set("ballot_public_access", "false");
    return <BaseComponent ballotId={ballotId} {...props} />;
  } else {
    return <div></div>;
  }
};

export default withPrivate;
