/* eslint-disable react/no-danger */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import axios from "utils/axios";
import useRouter from "utils/useRouter";
import getBallotId from "utils/getBallotId";
import parse from "html-react-parser";

// eslint-disable-next-line no-undef
// it contains full story tracking code also
// console.log(Cookies.get("ballot_id"));

const GoogleAnalytics = (props) => {
  const [generalData, setGeneralData] = useState();

  const [ballotId, setBallotId] = useState();

  const { history } = useRouter();

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getBallotId(props).then((id) => setBallotId(id));

      const axios_url =
        process.env.REACT_APP_BACK_END_SERVER_URL +
        "/server/v1/ballots/" +
        ballotId;

      ballotId &&
        axios.get(axios_url).then(
          (response) => {
            if (response.status === 200) {
              setGeneralData(response.data.general);
              // console.log("general data");
              // console.log(response.data.general.custom_header);
            } else {
              console.log(response.status);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }

    return () => {
      mounted = false;
    };
  }, [ballotId]);

  useEffect(() => {
    const gtag = window.gtag;
    if (generalData) {
      let tracking_id = generalData.google_analytics
        ? generalData.google_analytics.tracking_id
        : "UA-154986107-1";
      gtag &&
        gtag("config", tracking_id, {
          page_path: history.location.pathname,
        });
    }
  }, [history.location.pathname]);

  return (
    <>
      <div></div>
      {generalData && (
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-2043889-2"
          ></script>

          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          
          gtag('config', 'UA-2043889-2');
        `}
          </script>

          <script>
            {`
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', 'UA-154986107-1');
`}
          </script>

          {history.location.pathname &&
            generalData &&
            generalData.google_analytics && (
              <script>
                {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          
          gtag('config', ${"'" +
            generalData.google_analytics.tracking_id +
            "'"}, {
              page_location: window.location.href,
              page_path: ${history.location.pathname},
            });
        `}
              </script>
            )}

          {generalData &&
            generalData.custom_header &&
            parse(generalData.custom_header)}

          {generalData && generalData.meta_info && (
            <title>{generalData.meta_info.content.title}</title>
          )}
          {generalData && generalData.meta_info && (
            <meta
              name="description"
              content={generalData.meta_info.content.metadescription}
            />
          )}
          {generalData && generalData.meta_info && (
            <meta
              property="og:title"
              content={generalData.meta_info.content.metaogtitle}
            />
          )}
          {generalData && generalData.meta_info && (
            <meta
              property="og:description"
              content={generalData.meta_info.content.metaogdescription}
            />
          )}
          {generalData && generalData.meta_info && (
            <meta
              property="og:image"
              content={generalData.meta_info.content.metaogimage}
            />
          )}
          {generalData && generalData.meta_info && (
            <meta
              property="og:url"
              content={generalData.meta_info.content.metaogurl}
            />
          )}
          {generalData && generalData.meta_info && (
            <meta
              name="twitter:card"
              content={generalData.meta_info.content.metatwittercard}
            />
          )}
          {generalData && generalData.meta_info && (
            <meta
              property="og:site_name"
              content={generalData.meta_info.content.metaogsitename}
            />
          )}
          {generalData && generalData.meta_info && (
            <meta
              name="twitter:image:alt"
              content={generalData.meta_info.content.metatwitterimagealt}
            />
          )}

          {generalData && generalData.meta_info && (
            <meta
              property="fb:app_id"
              content={generalData.meta_info.content.metafbappid}
            />
          )}

          {generalData && generalData.meta_info && (
            <meta
              name="twitter:site"
              content={generalData.meta_info.content.metatwittersite}
            />
          )}
        </Helmet>
      )}
    </>
  );
};

export default GoogleAnalytics;
