import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { makeStyles } from "@material-ui/styles";

import { ExportToCsv } from "export-to-csv";
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  groupTextFieldExport: {
    marginBottom: theme.spacing(2),
    width: "20%",
    marginRight: "10px",
  },
  exportPageDrodown: {
    marginBottom: theme.spacing(2),
    width: "60%",
    marginRight: "10px",
    marginTop: "-5px",
  },
}));

const ExportDropDown = (props) => {
  const { className, exportFunction, exportPage, ...rest } = props;
  const [value, setValue] = useState();

  const [noOfAllwedCatExport, setnoOfAllwedCatExport] = useState([
    "all",
    "3",
    "5",
    "7",
    "10",
  ]);
  const classes = useStyles();
  const handleOnChange = (event) => {
    if (isNaN(event.target.value.toString())) {
      setValue("");
      alert("Please Enter Valid Number!");
      return;
    } else {
      setValue(event.target.value);
      exportFunction(event);
    }
  };
  return (
    <TextField
      fullWidth
      label="No.Of Entry need to Export"
      name="exportcount"
      onChange={handleOnChange}
      value={value}
      //select
      // eslint-disable-next-line react/jsx-sort-props
      //SelectProps={{ native: true }}
      variant="outlined"
      className={
        exportPage == "exportpage"
          ? classes.exportPageDrodown
          : classes.groupTextFieldExport
      }
    >
      {/* {noOfAllwedCatExport.map((option, key) => (
              <option key={key} value={option}>
                {option}
              </option>
            ))} */}
    </TextField>
  );
};

ExportDropDown.propTypes = {
  className: PropTypes.string,
};

export default ExportDropDown;
