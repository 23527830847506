import React, { useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Card, CardHeader, CardContent } from "@material-ui/core";

import { TinyMCEEditor } from "components";

const useStyles = makeStyles(() => ({
  root: {},
}));

const TextContentWithTinyMCE = (props) => {
  const {
    className,
    value,
    initialValue,
    onEditorChange,
    title,
    height,
    ...rest
  } = props;

  const classes = useStyles();
  const editorRef = useRef(null);

  return (
    <>
      <p>Description</p>

      <TinyMCEEditor
        initialValue={initialValue}
        value={value}
        onEditorChange={onEditorChange}
        customHeight={height ? height : 300}
      />
    </>
  );
};

TextContentWithTinyMCE.propTypes = {
  className: PropTypes.string,
};

export default TextContentWithTinyMCE;
