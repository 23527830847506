import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({
  payButton: {
    width: "100%",
    backgroundColor: "#666ee8",
    border: "1px solid #666ee8",
    color: "white",
    marginTop: "30px",
    padding: "15px",
    fontSize: "20px",
    fontWeight: "bold",
  },
  payForm: {
    margin: "0 auto",
  },
}));

export const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const { getPaymentTokenInfo, paymentButtonText, disabled } = props;
  const getProperPaymentText = (text) => {
    let tempIndex = text.indexOf("$");
    return tempIndex < 0 ? "Pay  $" + text + " USD" : "Pay " + text + " USD";
  };
  const [actualPaymentButtonText, setActualPaymentButtonText] = useState(
    getProperPaymentText(paymentButtonText)
  );
  const [validPaymentButtonText, setValidPaymentButtonText] = useState(
    paymentButtonText != "error" && getProperPaymentText(paymentButtonText)
  );

  const [ref, setRef] = useState(null);
  // if (ref && paymentButtonText == "error") {

  //   ref.clear();
  // }
  const handleSubmit = async (event) => {
    event.preventDefault();
    setActualPaymentButtonText("Processing...");
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (!error) {
      let paymentStatus = await getPaymentTokenInfo(paymentMethod);
      if (!paymentStatus) {
        ref.clear();
        setActualPaymentButtonText(validPaymentButtonText);
      }
    } else {
      let status = {
        error: true,
      };
      getPaymentTokenInfo(status);
      setActualPaymentButtonText(validPaymentButtonText);
      ref.clear();
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    iconStyle: "solid",

    style: {
      width: "100%",
      base: {
        iconColor: "rgb(240, 57, 122)",
        color: "rgb(240, 57, 122)",
        fontSize: "16px",
        fontFamily: '"Open Sans", sans-serif',
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#CFD7DF",
        },
        "::button": {
          width: "100%",
        },
      },
      invalid: {
        color: "#e5424d",
        ":focus": {
          color: "#303238",
        },
      },
    },
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{ maxWidth: 400 }}
      className={classes.payForm}
    >
      <CardElement options={CARD_ELEMENT_OPTIONS} onReady={(e) => setRef(e)} />
      <button className={classes.payButton} disabled={disabled}>
        {actualPaymentButtonText}
      </button>
    </form>
  );
};
