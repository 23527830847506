import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none"
  },
  mainIframe: {
    zIndex: 100
  },
  topBar4: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "60px !important",
      display: "block !important"
    }
  }
}));

const Topbar = props => {
  const { className, headerData, ...rest } = props;

  const getGeneratedPageURL = ({ html, css, js }) => {
    const getBlobURL = (code, type) => {
      const blob = new Blob([code], { type });
      return URL.createObjectURL(blob);
    };

    const cssURL = getBlobURL(css, "text/css");
    const jsURL = getBlobURL(js, "text/javascript");
    // console.log("header data");
    //console.log(headerData);

    const source = `
      <html>
        <head>
          ${css && `<link rel="stylesheet" type="text/css" href="${cssURL}" />`}
          ${js && `<script src="${jsURL}"></script>`}
        </head>
        <body>
          ${html || ""}
        </body>
      </html>
    `;

    return getBlobURL(source, "text/html");
  };

  const html = headerData && headerData.html;

  const url = getGeneratedPageURL({
    html: html,
    css: "",
    js: ""
  });

  // useEffect(() => {
  //   let htmlString = html;
  //   let extractedScript = /<script>[\s\S]*<\/script>/g.exec(htmlString)[0];
  //   let scriptToRun = extractedScript;
  //   if (scriptToRun !== undefined) {
  //     //remove <script> and </script> tags since eval expects only code without html tags
  //     let scriptLines = scriptToRun.split("\n");
  //     scriptLines.pop();
  //     scriptLines.shift();
  //     let cleanScript = scriptLines.join("\n");
  //     //console.log('running script ',cleanScript)
  //     window.eval(cleanScript);
  //   }
  // }, []);
  // // const iframe = document.querySelector('#iframe')
  // // iframe.src = url

  const classes = useStyles();
  return (
    <div
      className={classes.topBar4}
      dangerouslySetInnerHTML={{ __html: html }}
    ></div>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
