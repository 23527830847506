import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { makeStyles } from "@material-ui/styles";
import { Paper, Typography, Link, Button } from "@material-ui/core";
import Intercom from "react-intercom";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 420,
    position: "fixed",
    bottom: 0,
    right: 0,
    margin: theme.spacing(3),
    outline: "none",
    zIndex: 2000,
  },
  media: {
    padding: theme.spacing(1, 2),
    height: 180,
    textAlign: "center",
    "& > img": {
      height: "100%",
      width: "auto",
    },
  },
  content: {
    padding: theme.spacing(1, 2),
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(1, 2, 2, 1),
  },
}));

const IntercomSetup = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  //   useEffect(() => {
  //     const consent = Cookies.get("consent");

  //     if (!consent) {
  //       setOpen(true);
  //     }
  //   }, []);

  const handleClose = () => {
    Cookies.set("consent", "true");
    setOpen(false);
  };

  if (!open) {
    return null;
  }
  const user = {
    user_id: "33388s",
    email: "vindyala@gmail.com",
    name: "sripal",
  };

  return <Intercom appID="p43ayu3j" {...user} />;
};

export default IntercomSetup;
