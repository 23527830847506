/* eslint-disable no-undef */
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import useRouter from "utils/useRouter";

const NODE_ENV = process.env.NODE_ENV;
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

const Page = (props) => {
  const { title, children, ...rest } = props;

  const router = useRouter();

  useEffect(() => {
    // console.log("from page ");
    // console.log(window.location.host);
    if (NODE_ENV !== "production") {
      return;
    }

    if (window.gtag) {
      window.gtag("config", GA_MEASUREMENT_ID, {
        page_path: router.location.pathname,
        page_name: title,
      });
    }
  }, [title, router]);

  return (
    <div {...rest}>
      {window.location.host == "localhost:3000" && (
        <Helmet>
          <link rel="shortcut icon" href="/favicon.png" />
        </Helmet>
      )}
      {window.location.host == "bestof.scenethink.io" && (
        <Helmet>
          <link rel="shortcut icon" href="/favicon.png" />
          <meta name="title" content="Best of Scenethink 2020 - Scenethink" />
          <meta name="description" content="" />
          <meta name="keywords" content="" />
          <title>Best of Scenethink 2020 - Scenethink</title>
          <meta name="news_keywords" content="" />
          <link rel="image_src" href="https://bestof.scenethink.io" />
          <meta name="thumbnail" content="" />
          <link rel="canonical" href="" />
          <link
            rel="alternate"
            media="only screen and (max-width: 640px)"
            href=""
          />
          <meta property="og:url" content="" />
          {/* <meta property="fb:app_id" content="175028705897463" />
        <meta property="og:site_name" content="Charleston City Paper" />
        <meta property="og:title" content="Best of Charleston 2020" /> */}
          <meta property="og:image" content="" />
          <meta property="og:image:width" content="1504" />
          <meta property="og:image:height" content="872" />
          <meta property="og:description" content="" />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
          {/* <meta
          name="twitter:image"
          content="https://media2.fdncms.com/charleston/imager/u/slideshow/30144191/boc20_genericlogo.jpg"
        />
        <meta name="twitter:site" content="@ChasCityPaper" />
        <meta name="twitter:title" content="Best of Charleston 2020" />
        <meta
          name="twitter:description"
          content="The early bird gets the worm, and trust us, we&rsquo;re just excited about Best of Charleston 2020 as you are."
        /> */}
        </Helmet>
      )}

      {window.location.host ==
        "staging.bestof.scenethink.io.s3.us-east-2.amazonaws.com" && (
        <Helmet>
          <link rel="shortcut icon" href="/favicon.ico" />
          <meta name="title" content="Best of Staging 2020 - Staging" />
          <meta name="description" content="" />
          <meta name="keywords" content="" />
          <title>Best of Staging 2020 - Staging</title>
          <meta name="news_keywords" content="" />
          <link rel="image_src" href="https://bestof.scenethink.io" />
          <meta name="thumbnail" content="" />
          <link rel="canonical" href="" />
          <link
            rel="alternate"
            media="only screen and (max-width: 640px)"
            href=""
          />
          <meta property="og:url" content="" />
          {/* <meta property="fb:app_id" content="175028705897463" />
        <meta property="og:site_name" content="Charleston City Paper" />
        <meta property="og:title" content="Best of Charleston 2020" /> */}
          <meta property="og:image" content="" />
          <meta property="og:image:width" content="1504" />
          <meta property="og:image:height" content="872" />
          <meta property="og:description" content="" />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
          {/* <meta
          name="twitter:image"
          content="https://media2.fdncms.com/charleston/imager/u/slideshow/30144191/boc20_genericlogo.jpg"
        />
        <meta name="twitter:site" content="@ChasCityPaper" />
        <meta name="twitter:title" content="Best of Charleston 2020" />
        <meta
          name="twitter:description"
          content="The early bird gets the worm, and trust us, we&rsquo;re just excited about Best of Charleston 2020 as you are."
        /> */}
        </Helmet>
      )}

      {children}
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Page;
