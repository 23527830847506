import axios from "utils/axios";
import keen from "utils/keen";
import getBallotId from "utils/getBallotId";
import getSeoUnFriendlyWord from "utils/getSeoUnFriendlyWord";
export default (trackingData, dataRes, ballotId) => {
  const res = trackingData.split("/");
  const pageName = "/" + res[1] + "/" + res[2];
  let pathdetails = {
    pagename: pageName,
    ballotId: ballotId,
  };
  if (res[3]) {
    let chosenCategory = dataRes.parent_categories.find((data) => {
      return data.parent_category_name == getSeoUnFriendlyWord(res[3]);
    });
    if (chosenCategory) {
      let parentCatId = chosenCategory._id;
      let subCategories = chosenCategory.sub_categories;
      let catPathdetails = {
        categoryId: parentCatId,
        categoryName: getSeoUnFriendlyWord(res[3]),
      };
      pathdetails = { ...pathdetails, ...catPathdetails };
      if (res[4]) {
        let chosenSubCategory = subCategories.find((data) => {
          return data.name == getSeoUnFriendlyWord(res[4]);
        });
        let subcatCatId = chosenSubCategory._id;
        let subcatPathdetails = {
          subCategoryName: res[4] ? getSeoUnFriendlyWord(res[4]) : "",
          chosenSubCategoryId: subcatCatId,
        };
        pathdetails = { ...pathdetails, ...subcatPathdetails };
      }
      return pathdetails;
    } else {
      return pathdetails;
    }
  } else {
    return pathdetails;
  }
};
