import React, { useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Editor } from "@tinymce/tinymce-react";

const useStyles = makeStyles((theme) => ({}));

const TinyMCEEditor = (props) => {
  const { initialValue, value, onEditorChange, customHeight, ...rest } = props;
  let height = customHeight ? customHeight : 500;
  const editorRef = useRef(null);

  return (
    <Editor
      onInit={(evt, editor) => (editorRef.current = editor)}
      initialValue={initialValue}
      value={value}
      apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
      init={{
        height: height,
        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          " undo redo | formatselect | " +
          "bold italic backcolor | link alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent  | " +
          "removeformat | help",
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
      }}
      onEditorChange={onEditorChange}
    />
  );
};

TinyMCEEditor.propTypes = {
  className: PropTypes.string,
};

export default TinyMCEEditor;
