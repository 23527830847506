import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import useRouter from "utils/useRouter";
import checkProperBallotId from "utils/checkProperBallotId";
export default function withAuth(ComponentToProtect) {
  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
      };
    }
    componentDidMount() {
      let isDifferentBallotId = checkProperBallotId();
      if (isDifferentBallotId) {
        Cookies.set("ballot_id", sessionStorage.getItem("forthistabballotid"));
        Cookies.set(
          "ballot_name",
          sessionStorage.getItem("forthistabballotname")
        );
      }
      let axios_path_to =
        process.env.REACT_APP_BACK_END_SERVER_URL +
        "/server/v1/checkToken?token=" +
        Cookies.get("token");

      fetch(axios_path_to)
        .then((res) => {
          if (res.status === 200) {
            this.setState({ loading: false });
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
          this.setState({ loading: false, redirect: true });
        });
    }
    render() {
      const { loading, redirect } = this.state;
      if (loading) {
        return null;
      }
      if (redirect) {
        return <Redirect to="/auth/login" />;
      }
      return (
        <React.Fragment>
          <ComponentToProtect {...this.props} />
        </React.Fragment>
      );
    }
  };
}
